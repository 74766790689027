// @ts-nocheck

import React, {useEffect} from 'react';
import {useStyles} from './Styles';
import Box from '@mui/material/Box';
import Tooltip from "@mui/material/Tooltip";
import {Button, Card, CardContent, IconButton} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import {library} from '@fortawesome/fontawesome-svg-core';
import {useTranslation} from "react-i18next";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import CardActions from "@mui/material/CardActions";
import DataDisplayM from "../../../../Helpers/DataDisplayM/DataDisplayM";
import {log} from "../../../../../Services/LoggerService";
import { getAll, getById } from '../../../../../Services/GenericApiService';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function BlogView({data = null, isNotes}) {
    // css for module
    const classes = useStyles();

    // translation
    const {t} = useTranslation();

    // module(s) for api
    const moduleMain = 'blog';
    const moduleQuiz = 'quiz';

    // ui handlers
    const [open, setOpen] = React.useState(false);
    const [allFiles, setAllFiles] = React.useState([]);
    const [quiz, setAllQuiz] = React.useState(null);
    const [blogPath, setBlogPath] = React.useState(null);

    const handleOpen = () => {
        setOpen(true);
        getFiles(data.id);
        getQuiz(data?.blogBlogQuiz[0].quizId)
    }
    const handleClose = () => setOpen(false);

    const DocURL = process.env.REACT_APP_ENDPOINT;

    // handle cancel event
    const handleCancelEvent = () => {
        handleClose();
    }

    // hooks
    useEffect(() => {

    }, []);


    const getFiles = (_id: any) => {
       
        getAll(moduleMain + '/media' + '/' + _id)
            .then(_res => {
                setAllFiles(_res?.files);
                setBlogPath(_res?.basePath);
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
            })
    }

    const getQuiz = (_id: any) => {
       
        getById(moduleQuiz , _id)
            .then(_res => {
                setAllQuiz(_res?.name);
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
            })
    }


    return (
        <div>
            <Box sx={{display: 'flex'}}>
                <Tooltip title={t('common.view')}>
                    <IconButton aria-label="delete" size="small" onClick={handleOpen}>
                        <FontAwesomeIcon className="edit-btn" icon='eye'/>
                    </IconButton>
                </Tooltip>
            </Box>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                onBackdropClick={handleCancelEvent}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box className={classes.modal}>
                        <Card sx={{width: '75vw', maxHeight: '90vh', overflowY: 'auto'}}>
                            <CardContent>
                                <div className="py-4 px-md-4 px-0">
                                    <h1 className="mb-3">{t('common.recordDetails')}</h1>
                                    <div className="row full-width">

                                        
                                        <div className="col-md-6">
                                            {data &&
                                           <DataDisplayM label={t('blog.title')} data={data.title} />
                                            }                                        </div>

                                        <div className="col-md-6">
                                            {data &&
                                           <DataDisplayM label={t('blog.authorId')} data={data.blogUser?.email} />
                                            }                                       </div>

                                        <div className="col-md-6">
                                            {data && !isNotes &&
                                           <DataDisplayM label={t('blog.quiz')} data={quiz} />
                                            }                                       </div>


                                        <div className="col-md-12">
                                            {data &&
                                           <DataDisplayM label={t('blog.content')} data={data.content} />
                                            }                                        </div>
                                                                                

                                        <div className="col-md-6">
                                        <DataDisplayM label={t('blog.mediaFile')} data={null} />
                                        {data && allFiles && allFiles.map((item, index) => (
                                            <p>{item}
                                                <a href={DocURL+blogPath+item} target="_blank" class="px-2">
                                                    <FontAwesomeIcon icon="fa-solid fa-paperclip"/>
                                                </a>
                                            </p>
                                        ))}
                                        {data && allFiles.length == 0 &&
                                                    <p>N/A</p>
                                        }
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                            <CardActions>
                                <div className="pb-4 pt-2 px-md-4 px-2 " style={{width: '100%', textAlign: 'right'}}>

                                    {/* Cancel Button */}
                                    <Button variant="outlined"
                                            onClick={handleCancelEvent}>
                                        {t('common.cancel')}
                                    </Button>

                                    &nbsp;&nbsp;
                                </div>
                            </CardActions>
                        </Card>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

export default BlogView;
