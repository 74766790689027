import React, { useEffect } from 'react';
import { useStyles } from './Styles';
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import { post, getAll, getById } from "../../../../../Services/GenericApiService";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { log } from "../../../../../Services/LoggerService";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import { Button, FormControl, TextField, FormControlLabel, Checkbox, FormHelperText } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import { DatePicker } from '@mui/lab';
import FileUploadM from "../../../../Helpers/FileUploadM/FileUploadM";


// @ts-ignore
function RecordForm({ handleAddEvent, handleCancelEvent, isLoading, isEditMode, recordToUpdate, handleEditEvent }) {
    // css for module
    const classes = useStyles();

    // router object
    const navigate = useNavigate();

    // module(s) for api
    const moduleMain = 'record';

    // linked components API modules (endpoints)
    const moduleRecordtype = 'recordtype';
    const moduleRecordcategory = 'recordcategory/recordtype';
    const moduleRecordcategoryoption = 'recordcategoryoption/recordCategoryId';
    const moduleLocation = 'location';
    const moduleUser = 'user';


    // translation
    const { t } = useTranslation();

    // UI methods
    const [loading, setLoading] = React.useState(true);

    // form data
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm(); // watch is used to get the value e.g watch("name")

    // form methods
    const onSubmit = (_data: any) => {
        if (!isEditMode) {
            handleAddEvent(_data);
        } else {
            _data.recordCategoryId = selectedTouchCategory;
            _data.recordCategoryOptionId = selectedTouchCategoryOption;
            handleEditEvent(_data);
        }
    }

    // data vars


    // ui handlers
    const onCancel = () => {
        handleCancelEvent();
    }

    // fetch dependent data
    const [allRecordtype, setAllRecordtype] = React.useState([]);
    const [allRecordcategory, setAllcategory] = React.useState([]);
    const [allRecordcategoryoption, setAllRecordCategoryOption] = React.useState([]);
    const [allLocation, setAllLocation] = React.useState([]);
    const [allUser, setAllUser] = React.useState([]);
    const [selectedTouchType, setSelectedTouchType] = React.useState('');
    const [selectedTouchCategory, setSelectedTouchCategory] = React.useState('');
    const [selectedTouchCategoryOption, setSelectedTouchCategoryOption] = React.useState('');

    const fetch = () => {
        fetchRecordtype();
        fetchLocation();
        fetchUser();
        if(isEditMode) {
            let recordTypeId = recordToUpdate?.recordRecordCategory?.recordCategoryRecordtype?.id;
            let recordCategoryId = recordToUpdate?.recordCategoryId;
            let recordCategoryOptionId = recordToUpdate?.recordCategoryOptionId;
            setSelectedTouchType(recordTypeId);
            setSelectedTouchCategory(recordCategoryId);
            setSelectedTouchCategoryOption(recordCategoryOptionId);

            fetchRecordcategory(recordTypeId);
            fetchRecordcategoryoption(recordCategoryId);
        }
    }

    // fetch Recordcategory
    const fetchRecordcategory = (_id: any) => {
        setLoading(true); // show loader
        getById(moduleRecordcategory,_id)
            .then((_res: any) => {
                if (_res.length == 0) {
                    setSelectedTouchCategory('');
                    setSelectedTouchCategoryOption('');
                }
                setAllcategory(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }
    // fetch Recordcategoryoption
    const fetchRecordcategoryoption = (_id: any) => {
        setLoading(true); // show loader
        getById(moduleRecordcategoryoption, _id)
            .then((_res: any) => {
                if (_res.length == 0) {
                    setSelectedTouchCategoryOption('');
                }
                setAllRecordCategoryOption(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // fetch Location
    const fetchLocation = () => {
        setLoading(true); // show loader
        getAll(moduleLocation)
            .then((_res: any) => {
                setAllLocation(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // fetch User
    const fetchUser = () => {
        setLoading(true); // show loader
        getAll(moduleUser)
            .then((_res: any) => {
                setAllUser(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // fetch Recordtype
    const fetchRecordtype = () => {
        setLoading(true); // show loader
        getAll(moduleRecordtype)
            .then((_res: any) => {
                setAllRecordtype(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    const handleTouchTypeChange = (event:any) => {
        const selectedTouchTypeId = event.target.value;
        setAllcategory([]);
        setAllRecordCategoryOption([]);

        setSelectedTouchCategoryOption('');
        setSelectedTouchType(selectedTouchTypeId);

        fetchRecordcategory(selectedTouchTypeId);
    };

    const handleTouchCategoryChange = (event:any) => {
        const selectedTouchCategoryId = event.target.value;;
        console.log(selectedTouchCategoryId)
        setSelectedTouchCategory(selectedTouchCategoryId);

        fetchRecordcategoryoption(selectedTouchCategoryId);
    }

    const handleTouchTypeOptionChange = (event:any) => {
        const selectedTouchCategoryOptionId = event.target.value;

        setSelectedTouchCategoryOption(selectedTouchCategoryOptionId);
    };

    // hooks
    useEffect(() => {
        fetch();
        if (isEditMode) {
            reset(recordToUpdate);


        }
    }, []);


    return (
        <Box sx={{ display: 'flex' }}>
            <Card sx={{ width: '75vw', maxHeight: '90vh', overflowY: 'auto' }}>
                {/* Form start */}
                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <div className="py-2 px-md-4 px-2">
                            <div className="row">

                                <div className="col-md-12 my-2">
                                    {/* Form name */}
                                    <h3 className="pb-2">
                                        {isEditMode ? t('common.edit') : t('common.add')} {t('module.record')}
                                    </h3>

                                </div>



                                {/* Field comments */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="commentsInput"
                                            {...register("comments", { required: true })}
                                            label={t('record.comments')}
                                            type={"text"}
                                            error={!!errors.comments}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* touch type */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="recordTypeIdLabel">
                                            Touch Type
                                        </InputLabel>
                                        {allRecordtype.length > 0 &&
                                            <Select labelId="recordTypeIdLabel"
                                                id="recordTypeIdSelect"
                                                {...register("recordRecordCategory.recordCategoryRecordtype.id", { required: true })}
                                                error={!!errors.recordTypeId}
                                                value={selectedTouchType}
                                                onChange={handleTouchTypeChange}
                                                label="recordTypeId">
                                                {
                                                    allRecordtype.map((el: any) =>
                                                        <MenuItem value={el.id}
                                                            key={el.id}> {el.name}
                                                        </MenuItem>)
                                                }
                                            </Select>
                                        }
                                        {allRecordtype.length == 0 &&
                                            <>
                                                <Select labelId="recordTypeIdLabel"
                                                    disabled
                                                    id="recordTypeIdSelect"
                                                    {...register("recordRecordCategory.recordCategoryRecordtype.id", { required: true })}
                                                    error={!!errors.recordTypeId}
                                                    value={selectedTouchType}
                                                    label="recordTypeId">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>


                                {/* Touch category */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="recordCategoryIdLabel">
                                            Touch Category
                                        </InputLabel>
                                        {allRecordcategory.length > 0 &&
                                            <Select labelId="recordCategoryIdLabel"
                                                id="recordTypeIdSelect"
                                                {...register("recordCategoryId", { required: false })}
                                                error={!!errors.recordTypeId}
                                                value={selectedTouchCategory}
                                                onChange={handleTouchCategoryChange}
                                                label="recordCategoryId">
                                                {
                                                    allRecordcategory.map((el: any) =>
                                                        <MenuItem value={el.id}
                                                            key={el.id}> {el.name}
                                                        </MenuItem>)
                                                }
                                            </Select>
                                        }
                                        {allRecordcategory.length == 0 &&
                                            <>
                                                <Select labelId="recordCategoryIdLabel"
                                                    disabled
                                                    id="recordCategoryIdSelect"
                                                    {...register("recordCategoryId", { required: false })}
                                                    error={!!errors.recordTypeId}
                                                    value={selectedTouchCategory}
                                                    label="recordCategoryId">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>

                                {/* touch category option */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="recordCategoryOptionIdLabel">
                                            Touch Category Option
                                        </InputLabel>
                                        {allRecordcategoryoption.length > 0 &&
                                            <Select labelId="recordCategoryOptionIdLabel"
                                                id="recordCategoryOptionIdSelect"
                                                {...register("recordCategoryOptionId", { required: false })}
                                                error={!!errors.recordTypeId}
                                                value={selectedTouchCategoryOption}
                                                onChange={handleTouchTypeOptionChange}
                                                label="recordCategoryOptionId">
                                                {
                                                    allRecordcategoryoption.map((el: any) =>
                                                        <MenuItem value={el.id}
                                                            key={el.id}> {el.name}
                                                        </MenuItem>)
                                                }
                                            </Select>
                                        }
                                        {allRecordcategoryoption.length == 0 &&
                                            <>
                                                <Select labelId="recordCategoryOptionIdLabel"
                                                    disabled
                                                    id="recordCategoryOptionIdSelect"
                                                    {...register("recordCategoryOptionId", { required: false })}
                                                    error={!!errors.recordCategoryOptionId}
                                                    value={selectedTouchCategoryOption}
                                                    onChange={handleTouchTypeOptionChange}
                                                    label="recordCategoryOptionId">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>

                                {/* Field locationId */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="locationIdLabel">
                                            Sites
                                        </InputLabel>
                                        {allLocation.length > 0 &&
                                            <Select labelId="locationIdLabel"
                                                id="locationIdSelect"
                                                {...register("locationId", { required: true })}
                                                error={!!errors.locationId}
                                                value={watch('locationId')}
                                                label="locationId">
                                                {
                                                    allLocation.map((el: any) =>
                                                        <MenuItem value={el.id}
                                                            key={el.id}> {el.name}
                                                        </MenuItem>)
                                                }
                                            </Select>
                                        }
                                        {allLocation.length == 0 &&
                                            <>
                                                <Select labelId="locationIdLabel"
                                                    disabled
                                                    id="locationIdSelect"
                                                    {...register("locationId", { required: true })}
                                                    error={!!errors.locationId}
                                                    value={watch('locationId')}
                                                    label="locationId">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>

                                {/* Field userId */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="userIdLabel">
                                            User
                                        </InputLabel>
                                        {allUser.length > 0 &&
                                            <Select labelId="userIdLabel"
                                                id="userIdSelect"
                                                {...register("userId", { required: true })}
                                                error={!!errors.userId}
                                                value={watch('userId')}
                                                label="userId">
                                                {
                                                    allUser.map((el: any) =>
                                                        <MenuItem value={el.id}
                                                            key={el.id}> {el.email}
                                                        </MenuItem>)
                                                }
                                            </Select>
                                        }
                                        {allUser.length == 0 &&
                                            <>
                                                <Select labelId="locationIdLabel"
                                                    disabled
                                                    id="userIdSelect"
                                                    {...register("userId", { required: true })}
                                                    error={!!errors.userId}
                                                    value={watch('userId')}
                                                    label="userId">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>


                                {/* Attachment */}
                                <div className="col-md-12 mt-4">
                                    <FormControl fullWidth={true}>
                                        <FileUploadM />
                                    </FormControl>
                                </div>

                            </div>
                        </div>
                    </CardContent>
                    <CardActions>
                        <div className="pb-4 pt-2 px-md-4 px-2 " style={{ width: '100%', textAlign: 'right' }}>

                            {/* Cancel Button */}
                            <Button variant="outlined"
                                onClick={onCancel}>
                                {t('common.cancel')}
                            </Button>

                            &nbsp;&nbsp;

                            {/* Okay Button */}
                            <LoadingButton loading={isLoading}
                                type="submit"
                                variant="contained"
                                className="bg-custom-gradient">
                                {isEditMode ? t('common.edit') : t('common.add')}
                            </LoadingButton>
                            &nbsp;
                        </div>
                    </CardActions>
                </form>
            </Card>
        </Box>
    );
}

export default RecordForm;
