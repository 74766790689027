// @ts-nocheck
import React, { useEffect } from 'react';
import Chart from 'react-apexcharts'
import { bgColor } from "../../meta/metaColors";
import "../../../../../../index.scss";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { switchTheme } from "../../../../../../Global/Actions";

const ApexDonutChart = ({
    isDarkTheme = false,
    height = "200%",
    width = "100%",
    chartTitle = "Chart",
    legendPosition = 'bottom',
    data = [],
    labels = []
}) => {

    const [isDarkThemeEnabled, setIsDarkThemeEnabled] = React.useState(isDarkTheme);

    // Redux
    const theme = useSelector(
        (state: RootStateOrAny) => state.global.global.theme,
    );

    useEffect(() => {
        setIsDarkThemeEnabled(theme == 'dark');
    }, [theme]);

    const config = {
        series: data,
        options: {
            colors: bgColor,
            legend: {
                position: legendPosition,
                labels: {
                    colors: isDarkThemeEnabled ? "white" : "black",
                    useSeriesColors: false
                }
            },
            chart: {
                id: 'donutChart',
                toolbar: {
                    show: false,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                        download: true,
                        selection: true,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: true,
                        reset: true | '<img src="/static/icons/reset.png" width="20">',
                        customIcons: []
                    },
                },
                export: {
                    csv: {
                        filename: undefined,
                        columnDelimiter: ',',
                        headerCategory: 'Category',
                        headerValue: 'Value',
                        dateFormatter(timestamp) {
                            return new Date(timestamp).toDateString()
                        }
                    },
                    png: {
                        filename: chartTitle.replaceAll(" ", "_") + new Date().toISOString(),
                    }
                },
            },
            labels: labels,
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        type: 'donut',
                    },
                    legend: {
                        position: legendPosition
                    }
                }
            }]
        }
    }

    return (
        <>
            {data != null && labels != null &&
                <>
                    <h6 className={'py-1 mb-3 fw-bolder bg-custom-gradient text-white rounded'}>
                        &nbsp;&nbsp;{chartTitle}
                    </h6>
                    {Math.max(...data) === 0 &&
                        <h5 className={'py-2 mb-5 text-center'}>No Data</h5>
                    }
                    {Math.max(...data) !== 0 &&
                        <Chart
                            options={config.options}
                            series={config.series}
                            type="donut"
                            width={width}
                            height={height}
                        />
                    }
                </>
            }
        </>
    )
}

export default ApexDonutChart;
