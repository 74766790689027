// @ts-nocheck
import React, {useEffect, useRef} from 'react';
import Box from '@mui/material/Box';
import {Line} from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ScriptableContext
} from "chart.js";
import { bgColor, borderColor } from '../meta/metaColors';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);



function LineChart({dataset = null, labels = null, title = '', axisLabel = '', height = ''}) {

    // const borderColor = ['rgba(230, 25, 75, 1)', 'rgba(60, 180, 75, 1)', 'rgba(255, 225, 25, 1)', 'rgba(0, 130, 200, 1)', 'rgba(245, 130, 48, 1)', 'rgba(145, 30, 180, 1)', 'rgba(70, 240, 240, 1)', 'rgba(240, 50, 230, 1)', 'rgba(210, 245, 60, 1)', 'rgba(250, 190, 212, 1)', 'rgba(0, 128, 128, 1)', 'rgba(220, 190, 255, 1)', 'rgba(170, 110, 40, 1)', 'rgba(255, 250, 200, 1)', 'rgba(128, 0, 0, 1)', 'rgba(170, 255, 195, 1)', 'rgba(128, 128, 0, 1)', 'rgba(255, 215, 180, 1)', 'rgba(0, 0, 128, 1)', 'rgba(128, 128, 128, 1)', 'rgba(255, 255, 255, 1)', 'rgba(0, 0, 0, 1)'];
    // const bgColor = ['rgba(230, 25, 75, 0.2)', 'rgba(60, 180, 75, 0.2)', 'rgba(255, 225, 25, 0.2)', 'rgba(0, 130, 200, 0.2)', 'rgba(245, 130, 48, 0.2)', 'rgba(145, 30, 180, 0.2)', 'rgba(70, 240, 240, 0.2)', 'rgba(240, 50, 230, 0.2)', 'rgba(210, 245, 60, 0.2)', 'rgba(250, 190, 212, 0.2)', 'rgba(0, 128, 128, 0.2)', 'rgba(220, 190, 255, 0.2)', 'rgba(170, 110, 40, 0.2)', 'rgba(255, 250, 200, 0.2)', 'rgba(128, 0, 0, 0.2)', 'rgba(170, 255, 195, 0.2)', 'rgba(128, 128, 0, 0.2)', 'rgba(255, 215, 180, 0.2)', 'rgba(0, 0, 128, 0.2)', 'rgba(128, 128, 128, 0.2)', 'rgba(255, 255, 255, 0.2)', 'rgba(0, 0, 0, 0.2)'];

    const options = {
        responsive: true,
        maintainAspectRatio: height == '' && width == '',
        fill: true,
        plugins: {
            tooltip: {
                callbacks: {
                    label: function(context) {
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += context.parsed.y + ' ' + axisLabel;
                        }
                        return label;
                    }
                }
            },
            legend: {
                position: 'top' as const,
            }
        },
    };

    const data = {
        labels,
        datasets: [],
    };

    if (dataset != null) {
        dataset.forEach((_record, _i) => {
            data.datasets.push({
                label: _record.label,
                data: _record.data,
                fill: "start",
                backgroundColor: (context: ScriptableContext<"line">) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 0, 0, 200);
                    gradient.addColorStop(0, borderColor[_i]);
                    gradient.addColorStop(1, bgColor[_i]);
                    return gradient;
                },
                borderColor: borderColor[_i]
            });
        })
    }

    return (
        <div className={'mb-4'}  style={{
            height: height
        }}>
            <h6 className={'py-3 mt-2 me-4 ms-4 mb-2 d-flex flex-row fw-bolder bg-custom-gradient text-white rounded'}>
                &nbsp;&nbsp;<span className="my-auto">{title}</span>
            </h6>

            {dataset!=null && labels!=null &&
                <Line options={options} data={data}/>
            }

        </div>
    );

}

export default LineChart;
