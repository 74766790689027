// @ts-nocheck

import React, {useEffect, useState, useRef} from 'react';
import '../../../../index.scss';
import {useTranslation} from "react-i18next";

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MUIDataTable from "mui-datatables";
import {Button, IconButton, Skeleton} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import {library} from '@fortawesome/fontawesome-svg-core';
import Tooltip from '@mui/material/Tooltip';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function Privacypolicy() {

    // translation
    const {t} = useTranslation();

    // child ref for alert
    const notifications = useRef();


    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>

            {/* Content */}
            <Box component="main" >

                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    {/*<a className="navbar-brand" href="#">Navbar</a>*/}
                    {/*<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"*/}
                    {/*        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">*/}
                    {/*    <span className="navbar-toggler-icon"></span>*/}
                    {/*</button>*/}
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item active">
                                <a className="nav-link " href="https://hailosafetytouch.bbarray.com/dashboard">CMS</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active" href="https://hailosafetytouch.bbarray.com/privacy">Privacy Policy</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://hailosafetytouch.bbarray.com/terms">Terms & Conditions</a>
                            </li>
                        </ul>
                    </div>
                </nav>

                <div className="p-3">
                    <h1>Privacy Policy</h1>
                    <p>Last updated: 27th July 2023</p>
                    <p>Thank you for using Hailo Safety Touch ("the App"). This Privacy Policy is designed to inform you about the types of data we collect, how we use and share it, and your rights regarding your personal information. Please read this policy carefully to understand how we handle your data.</p>
                    <h2>1. Introduction</h2>
                    <p>Hailo Safety Touch is an app used on oil rigs to register safety touches and environmental touches. Safety touches refer to safety issues reported on the oil rig, while environmental touches pertain to environmental issues. The App utilizes location data from your mobile device to determine if you are on-site and uses the camera to collect media evidence, such as images or videos, to support your reports.</p>
                    <h2>2. Data Collection</h2>
                    <p>We collect the following types of data from you:</p>
                    <ul>
                        <li>Location Data: The App uses GPS and location services on your mobile device to verify your presence on the oil rig when submitting safety and environmental touches.</li>
                        <li>Media Evidence: When you report a safety or environmental touch, the App may collect images or videos captured by your device's camera to provide additional context to your report.</li>
                        <li>Account Information: To use the App, you may need to create an account. We collect your username, email address, and contact details to facilitate communication regarding your reports and App updates.</li>
                    </ul>
                    <h2>3. Data Usage</h2>
                    <p>The data collected from you is used for the following purposes:</p>
                    <ul>
                        <li>Reporting Safety and Environmental Concerns: Your location data and media evidence are used to register and address safety and environmental issues on the oil rig effectively.</li>
                        <li>Communication: We may use your contact information to communicate with you regarding your reports and updates related to the App.</li>
                    </ul>
                    <h2>4. Data Sharing</h2>
                    <p>We do not sell or share your personal information with third parties for marketing purposes. However, in certain circumstances, we may share your data with the following entities:</p>
                    <ul>
                        <li>Oil Rig Authorities: To ensure proper resolution of reported safety and environmental touches, we may share your data with the relevant authorities on the oil rig.</li>
                        <li>Service Providers: We may engage trusted service providers to assist us in delivering the App's functionalities, and they may have access to your data strictly for that purpose.</li>
                        <li>Legal Compliance: We may share your data if required by law or in response to valid legal requests.</li>
                    </ul>
                    <h2>5. Data Storage and Security</h2>
                    <p>We take data security seriously and implement appropriate technical and organizational measures to protect your information from unauthorized access or disclosure. Your data is stored on secure servers with restricted access.</p>
                    <h2>6. User Rights</h2>
                    <p>As a user of the App, you have the following rights:</p>
                    <ul>
                        <li>Access: You have the right to request access to the personal information we hold about you.</li>
                        <li>Rectification: If you believe that any of your personal information is inaccurate or incomplete, you can request us to correct it.</li>
                        <li>Deletion: You can request the deletion of your personal data in certain circumstances, subject to legal requirements.</li>
                    </ul>
                    <p>To exercise any of these rights or for any privacy-related inquiries, please contact us at:</p>
                    <ul>
                        <li><strong>Contact Email:</strong> contact@bbarray.com</li>
                        <li><strong>Company Name:</strong> BBA Information Technology LLC</li>
                        <li><strong>Company Address:</strong> 407 Ibn Battuta Gate, Jebel Ali Village, Dubai, UAE</li>
                    </ul>
                    <h2>7. Cookies and Tracking</h2>
                    <p>The App does not use cookies or tracking technologies to collect additional user data.</p>
                    <h2>8. Updates to the Policy</h2>
                    <p>We may update this Privacy Policy from time to time to reflect changes in the App or legal requirements. We will notify you of any significant updates through the App or via email.</p>
                    <p>By using Hailo Safety Touch, you agree to the terms outlined in this Privacy Policy. If you do not agree with this policy, please refrain from using the App.</p>

                </div>

            </Box>


        </Box>
    );
}

export default Privacypolicy;
