// @ts-nocheck

import React, { useEffect, useState, useRef } from 'react';
import { useStyles } from './Styles';
import '../../../../index.scss';
import Header from "../../../Layout/Header/Header";
import Sider from "../../../Layout/Sider/Sider";
import { useTranslation } from "react-i18next";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import RecordtypeMain from "../Recordtype/RecordtypeMain/RecordtypeMain";
import RecordcategoryMain from "../Recordcategory/RecordcategoryMain/RecordcategoryMain";
import RecordcategoryoptionMain from '../Recordcategoryoption/RecordcategoryoptionMain/RecordcategoryoptionMain';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

const CustomTabPanel = ({ children, value, index }) => {
    return <div hidden={value !== index}>{children}</div>;
};

function TouchConfiguration() {
    // css for module
    const classes = useStyles();

    // translation
    const { t } = useTranslation();

    // child ref for alert
    const notifications = useRef();

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            {/* Header */}
            <Header />

            {/* Sider */}
            <Sider />

            {/* Content */}
            <Box component="main" sx={{ flexGrow: 2, p: 3 }} mt={10}>
                <div className="px-1">

                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Touch Type" />
                                <Tab label="Touch Categories" />
                                <Tab label="Touch Categories Option" />
                            </Tabs>
                        </Box>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <RecordtypeMain />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <RecordcategoryMain />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <RecordcategoryoptionMain />
                    </CustomTabPanel>
                </div>
            </Box>
        </Box>
    );
}

export default TouchConfiguration;
