// @ts-nocheck
import React, { useEffect } from 'react';
import { useStyles } from './Styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import TextDisplay from "../Chart/TextDisplay/TextDisplay";
import ApexDonutChart from '../Chart/Apex/ApexDonutChart/ApexDonutChart';
import Header from "../../../Layout/Header/Header";
import Sider from "../../../Layout/Sider/Sider";
import { getAll } from "../../../../Services/GenericApiService";
import { log } from "../../../../Services/LoggerService";
import StackedBarChart from '../Chart/StackedBarChart/StackedBarChart';
import LineChart from '../Chart/LineChart/LineChart';
import DoughnutChart from '../Chart/DoughnutChart/DoughnutChart';

function Home() {
    const classes = useStyles();

    // module(s) for api
    const moduleRecordOverview = 'dashboard/overview/all';
    const moduleRecordByLocaitonChartData = 'dashboard/heatmap/location';
    const moduleQuizByUserQuizChartData = 'dashboard/quizParticipation';
    const moduleQuizAttemptedTimelines = 'dashboard/quizAttemptsTimeline';
    const moduleOptionAgainstCateogry = 'dashboard/allCateogry/touchCategoryOptions/all';
    const moduleTouchByTouchCateogoryChartData = 'dashboard/allTouches/touchCategory';
    const moduleTouchByTouchCateogoryOptionsChartData = 'dashboard/allTouches/touchCategoryOptions';
    const moduleAllRecordTypeChartData = 'recordtype';

    // data vars
    const [allRecordsData, setAllRecordsData] = React.useState({});
    const [recordByLocationChartData, setRecordByLocationChartData] = React.useState({});
    const [quizByUserQuizChartData, setQuizByUserQuizChartData] = React.useState({});
    const [allRecordCategoryChartData, setAllRecordCategoryChartData] = React.useState({});
    const [allRecordTypeChartData, setAllRecordTypeChartData] = React.useState([]);
    const [allRecordCategoryOptionsChartData, setAllRecordCategoryOptionsChartData] = React.useState({});
    const [allQuizAttemptsTimelineData, setAllQuizAttemptsTimelineData] = React.useState({});
    const [touchesByTouchTypeChartData, setTouchesByTouchTypeChartData] = React.useState({});
    const [touchesByTouchTypeCategoryChartData, setTouchesByTouchTypeCategoryChartData] = React.useState({});
    const [touchesByTouchTypeCategoryOptionsChartData, setTouchesByTouchTypeCategoryOptionsChartData] = React.useState({});
    const [optionByCateogry, setOptionByCategory] = React.useState([]);
    const [optionCategory, setOptionCategory] = React.useState([]);

    // ui controls
    const [loading, setLoading] = React.useState(true);

    // fetch dependent data
    const fetch = () => {
        fetchRecordsOverview();
        fetchTouchesByTouchTypeCategory();
        fetchTouchesByTouchTypeCategoryOptions();
        fetchRecordByLocationChartData();
        fetchQuizByUserQuizChartData();
        fetchRecordTypeChartData();
        fetchQuizAttemptsTimeline();
        fetchOptionAgainstCategory();
    }

    const fetchRecordsOverview = () => {
        setLoading(true); // show loader
        getAll(moduleRecordOverview)
            .then((_res: any) => {
                // set all data
                setAllRecordsData(_res);
                let labels = [];
                let data = [];
                _res?.records.forEach(element => {
                    labels.push(element.name);
                    data.push(element.recordCount);
                });

                setTouchesByTouchTypeChartData({
                    lables: labels,
                    dataset: [{
                        label: "Touch Count By Touch Type",
                        data: data
                    }]
                });
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    const fetchTouchesByTouchTypeCategory = () => {
        setLoading(true); // show loader
        getAll(moduleTouchByTouchCateogoryChartData)
            .then((_res: any) => {
                // restructure data
                let labels = [];
                let data = [];
                _res.forEach(element => {
                    labels.push(element.name);
                    data.push(element.recordCount);
                });

                setTouchesByTouchTypeCategoryChartData({
                    lables: labels,
                    dataset: [{
                        label: "Touch Count By Touch Category",
                        data: data
                    }]
                });
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    const fetchTouchesByTouchTypeCategoryOptions = () => {
        setLoading(true); // show loader
        getAll(moduleTouchByTouchCateogoryOptionsChartData)
            .then((_res: any) => {
                // restructure data
                let labels = [];
                let data = [];

                _res.forEach(element => {
                    labels.push(element.name);
                    data.push(element.recordCount);
                });

                setTouchesByTouchTypeCategoryOptionsChartData({
                    lables: labels,
                    dataset: [{
                        label: "Touch Count By Touch Category",
                        data: data
                    }]
                });
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    const fetchRecordByLocationChartData = () => {
        setLoading(true); // show loader
        getAll(moduleRecordByLocaitonChartData)
            .then((_res: any) => {
                // restructure data
                let labels = [];
                let data = [];
                _res.forEach(element => {
                    labels.push(element.name);
                    data.push(element.recordCount);
                });
                setRecordByLocationChartData({
                    lables: labels,
                    dataset: [{
                        label: "Record Count",
                        data: data
                    }]
                });
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    const fetchQuizByUserQuizChartData = () => {
        setLoading(true); // show loader
        getAll(moduleQuizByUserQuizChartData)
            .then((_res: any) => {
                // restructure data
                let labels = [];
                let data = [];
                _res.forEach(element => {
                    labels.push(element.name);
                    data.push(element.totalAttempted);
                });
                setQuizByUserQuizChartData({
                    lables: labels,
                    dataset: [{
                        label: "Quiz Participation Count",
                        data: data
                    }]
                });
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    const fetchRecordTypeChartData = () => {
        setLoading(true); // show loader
        getAll(moduleAllRecordTypeChartData)
            .then((_res: any) => {

                let recordTypeName = [];
                let recordCategoryName = {};
                let recordCategoryOptionName = {};

                _res.forEach(element => {
                    recordTypeName.push(element.name);
                    recordCategoryName[element.name] = [];
                    element.recordTypeRecordCategory.forEach(_recordCategory => {
                        recordCategoryName[element.name].push(_recordCategory.name);
                        recordCategoryOptionName[_recordCategory.name] = [];
                        _recordCategory.recordCategoryRecordCategoryOption.forEach(_recordCategoryOption => {
                            recordCategoryOptionName[_recordCategory.name].push(_recordCategoryOption.name);
                        })
                    });

                });

                setAllRecordTypeChartData(recordTypeName);
                setAllRecordCategoryChartData(recordCategoryName);
                setAllRecordCategoryOptionsChartData(recordCategoryOptionName);
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    const fetchQuizAttemptsTimeline = () => {
        setLoading(true); // show loader
        getAll(moduleQuizAttemptedTimelines)
            .then((_res: any) => {
                // set all data
                let labels = [];
                let data = [];
                _res.count.forEach(_count => {
                    labels.push(new Date(_count.startTime).toLocaleDateString("en-US", { month: 'long', day: 'numeric' }));
                    data.push(_count.count);
                })

                setAllQuizAttemptsTimelineData({
                    labels: labels,
                    dataset: [{
                        data: data,
                        label: 'Quizzes Attempted'
                    }]
                })

                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    const fetchOptionAgainstCategory = () => {
        setLoading(true); // show loader
        getAll(moduleOptionAgainstCateogry)
            .then((_res: any) => {

                // Create an object to store the transformed data
                const transformedData = {};

                // Loop through the original data to transform it
                _res.forEach(item => {
                    const categoryName = item.recordCategoryOptionRecordCategory.name;
                    if (!transformedData[categoryName]) {
                        transformedData[categoryName] = {
                            name: categoryName,
                            label: [],
                            count: []
                        };
                    }
                    transformedData[categoryName].label.push(item.name);
                    transformedData[categoryName].count.push(item.recordCount);
                });

                // Convert the transformed data into an array
                const result = Object.values(transformedData);

                setOptionCategory(result);

                // set all data
                setOptionByCategory(_res);
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }


    useEffect(() => {
        fetch();
    }, [])

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            <Header />

            <Sider />


            <Box component="main" sx={{ flexGrow: 2, p: 3 }} mt={10}>
                <h2 className={'fw-bold'}>Overview</h2>
                <div className="row">
                    <div className="col-md-4 mt-3">
                        <TextDisplay label={'Total Users'} data={allRecordsData?.userCount} isLoading={loading} />
                    </div>
                    <div className="col-md-4 mt-3">
                        <TextDisplay label={'Active Sites'} data={allRecordsData?.locationCount} isLoading={loading} />
                    </div>
                    {Object.keys(allRecordsData).length > 0 &&
                        allRecordsData.records.map(_record => (
                            <div className="col-md-4 mt-3">
                                <TextDisplay label={'Total ' + _record.name} data={_record.recordCount}
                                    isLoading={loading} />
                            </div>
                        )
                        )
                    }
                </div>
                <div className="row mt-5">
                    <div className="col-md-4 ">
                        {Object.keys(touchesByTouchTypeChartData).length > 0 &&
                            <div className="card shadow-lg bg-dark rounded mt-5 pb-5">
                                <ApexDonutChart
                                    data={touchesByTouchTypeChartData?.dataset[0].data}
                                    labels={touchesByTouchTypeChartData?.lables}
                                    chartTitle={'Touches Count By Touch Types'}
                                />
                            </div>
                        }

                    </div>

                    <div className="col-md-4 ">
                        {Object.keys(touchesByTouchTypeCategoryChartData).length > 0 &&
                            <div className="card shadow-lg bg-dark rounded mt-5 pb-5">
                                <ApexDonutChart
                                    data={touchesByTouchTypeCategoryChartData?.dataset[0].data}
                                    labels={touchesByTouchTypeCategoryChartData?.lables}
                                    chartTitle={'Touches Count By Touch Category'}
                                />

                            </div>
                        }

                    </div>

                    <div className="col-md-4 ">
                        {Object.keys(touchesByTouchTypeCategoryOptionsChartData).length > 0 &&
                            <div className="card shadow-lg bg-dark rounded mt-5 pb-5">
                                <ApexDonutChart
                                    data={touchesByTouchTypeCategoryOptionsChartData?.dataset[0].data}
                                    labels={touchesByTouchTypeCategoryOptionsChartData?.lables}
                                    chartTitle={'Touches Count By Touch Category Options'}
                                />

                            </div>
                        }

                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        {Object.keys(recordByLocationChartData).length > 0 &&
                            <div className="card shadow-lg bg-dark rounded mt-5 pb-5">
                                <StackedBarChart labels={recordByLocationChartData.lables}
                                    dataset={recordByLocationChartData.dataset}
                                    title={'Touch Count By Sites'} height={'50vh'} />
                            </div>
                        }
                    </div>

                    <div className="col-md-6">
                        {Object.keys(quizByUserQuizChartData).length > 0 &&
                            <div className="card shadow-lg bg-dark rounded mt-5 pb-5">
                                <StackedBarChart labels={quizByUserQuizChartData.lables}
                                    dataset={quizByUserQuizChartData.dataset}
                                    title={'Quiz Participation'} height={'50vh'} />
                            </div>
                        }
                    </div>

                    <div className="col-md-12">
                        {Object.keys(allQuizAttemptsTimelineData).length > 0 &&
                            <div className="card shadow-lg bg-dark rounded mt-5 pb-5">
                                <LineChart labels={allQuizAttemptsTimelineData.labels}
                                    dataset={allQuizAttemptsTimelineData.dataset}
                                    title={'Quiz Timeline'} height={'30vh'} />
                            </div>
                        }
                    </div>
                </div>

                {/* Pie charts */}
                <div className="row">
                    {optionCategory.length > 0 &&
                        optionCategory.map((item, index) => (
                            <div className="col-md-4" key={index}>
                                <div className="card shadow-lg bg-dark rounded mt-5 pb-5">
                                    <ApexDonutChart
                                        data={item.count}
                                        labels={item.label}
                                        chartTitle={item.name}
                                    />
                                </div>
                            </div>
                        ))
                    }
                </div>
            </Box>
        </Box>
    );
}

export default Home;
