import React, { useEffect } from 'react';
import { useStyles } from './Styles';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Button, FormControl, TextField, FormControlLabel, Checkbox, InputAdornment, IconButton, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { encrypt } from '../../../../../Services/CryptoService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAll } from '../../../../../Services/GenericApiService';
import { log } from "../../../../../Services/LoggerService";

// @ts-ignore
function UserForm({ handleAddEvent, handleCancelEvent, isLoading, isEditMode, recordToUpdate, handleEditEvent }) {
    // css for module
    const classes = useStyles();

    // router object
    const navigate = useNavigate();

    // module(s) for api
    const moduleMain = 'user';
    const roleModule = 'role';
    const teamModule = 'team';

    // linked components API modules (endpoints)


    // translation
    const { t } = useTranslation();

    // UI methods
    const [loading, setLoading] = React.useState(true);
    const [isPasswordEditable, setIsPasswordEditable] = React.useState(false);

    // form data
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm(); // watch is used to get the value e.g watch("name")

    // form methods
    const onSubmit = (_data: any) => {
        let dataObj = { ..._data }

        if (isPasswordEditable) {
            if (!_data.password || _data.password == '' || _data.password == ' ')
                delete dataObj.password

            dataObj.password = encrypt(dataObj.password)
        }
        else delete dataObj.password

        if (!isEditMode) {
            handleAddEvent(dataObj);
        } else {
            handleEditEvent(dataObj);
        }
    }


    // data vars
    const [isCheckedIsactive, setIsCheckedIsactive] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const [allRole, setAllRole] = React.useState([]);
    const [allTeam, setAllTeam] = React.useState([]);
    const [selectedTeamId, setSelectedTeamId] = React.useState('');

    // ui handlers
    const onCancel = () => {
        handleCancelEvent();
    }

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    // fetch dependent data

    const handleTeamChange = (event:any) => {
        const selectedTeamId = event.target.value;
        setSelectedTeamId(selectedTeamId);
    };


    const fetch = () => {
        fetchRole();
        fetchTeam();
        if(isEditMode) {
            let teamId = recordToUpdate.userTeamUser?.teamUserTeam?.id;
            setSelectedTeamId(teamId);
        }
    }

    // fetch Role
    const fetchRole = () => {
        setLoading(true); // show loader
        getAll(roleModule)
            .then((_res: any) => {
                setAllRole(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // fetch teams
    const fetchTeam = () => {
        setLoading(true); // show loader
        getAll(teamModule)
            .then((_res: any) => {
                setAllTeam(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }


    // hooks
    useEffect(() => {
        fetch();
        setIsPasswordEditable(true);
        if (isEditMode) {
            reset(recordToUpdate);
            setIsPasswordEditable(false)
            setIsCheckedIsactive(recordToUpdate.isActive);

        }
    }, []);


    return (
        <Box sx={{ display: 'flex' }}>
            <Card sx={{ width: '75vw', maxHeight: '90vh', overflowY: 'auto' }}>
                {/* Form start */}
                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <div className="py-2 px-md-4 px-2">
                            <div className="row">

                                <div className="col-md-12 my-2">
                                    {/* Form name */}
                                    <h3 className="pb-2">
                                        {isEditMode ? t('common.edit') : t('common.add')} {t('module.user')}
                                    </h3>

                                </div>



                                {/* Field firstName */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="firstNameInput"
                                            {...register("firstName", { required: true })}
                                            label={t('user.firstName')}
                                            type={"text"}
                                            error={!!errors.firstName}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field middleName */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="middleNameInput"
                                            {...register("middleName", { required: false })}
                                            label={t('user.middleName')}
                                            type={"text"}
                                            error={!!errors.middleName}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field lastName */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="lastNameInput"
                                            {...register("lastName", { required: true })}
                                            label={t('user.lastName')}
                                            type={"text"}
                                            error={!!errors.lastName}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field email */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="emailInput"
                                            {...register("email", { required: true })}
                                            label={t('user.email')}
                                            type={"text"}
                                            error={!!errors.email}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field contact */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="contactInput"
                                            {...register("contact", { required: true })}
                                            label={t('user.contact')}
                                            type={"text"}
                                            error={!!errors.contact}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field team */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="teamIdLabel">
                                            Team
                                        </InputLabel>
                                        {allTeam.length > 0 &&
                                            <Select labelId="teamIdLabel"
                                                id="teamIdSelect"
                                                {...register("teamId", { required: false })}
                                                error={!!errors.teamId}
                                                value={selectedTeamId}
                                                onChange={handleTeamChange}
                                                label="teamId">
                                                {
                                                    allTeam.map((el: any) =>
                                                        <MenuItem value={el.id}
                                                            key={el.id}> {el.name}
                                                        </MenuItem>)
                                                }
                                            </Select>
                                        }
                                        {allTeam.length == 0 &&
                                            <>
                                                <Select labelId="teamIdLabel"
                                                    disabled
                                                    id="teamIdSelect"
                                                    {...register("teamId", { required: false })}
                                                    error={!!errors.teamId}
                                                    value={selectedTeamId}
                                                    onChange={handleTeamChange}
                                                    label="teamId">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>

                                {/* Field roleId */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="roleIdLabel">
                                            Role
                                        </InputLabel>
                                        {allRole.length > 0 &&
                                            <Select labelId="roleIdLabel"
                                                id="roleIdSelect"
                                                {...register("roleId", { required: true })}
                                                error={!!errors.roleId}
                                                value={watch('roleId')}
                                                label="roleId">
                                                {
                                                    allRole.map((el: any) =>
                                                        <MenuItem value={el.id}
                                                            key={el.id}> {el.title}
                                                        </MenuItem>)
                                                }
                                            </Select>
                                        }
                                        {allRole.length == 0 &&
                                            <>
                                                <Select labelId="roleIdLabel"
                                                    disabled
                                                    id="roleIdSelect"
                                                    {...register("roleId", { required: true })}
                                                    error={!!errors.roleId}
                                                    value={watch('roleId')}
                                                    label="roleId">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>

                                {/* Field checkIsPasswordEditable */}
                                {true && isEditMode &&
                                    <>
                                        <div className="col-md-6 mt-4">
                                            <div className="row">

                                                <div className="col-md-12">
                                                    <FormControl >
                                                        <FormControlLabel
                                                            value={isPasswordEditable}
                                                            label={'Update Password'}
                                                            control={<Checkbox checked={isPasswordEditable}
                                                                onChange={(_event: React.ChangeEvent<HTMLInputElement>) => {
                                                                    setIsPasswordEditable(!isPasswordEditable)
                                                                    if (!_event.target.checked) setValue("password", '');
                                                                }} />}
                                                        />
                                                    </FormControl>
                                                </div>

                                                <div className="col-md-12">
                                                    {/* Field password */}
                                                    {isPasswordEditable &&
                                                        <FormControl fullWidth>
                                                            <TextField id="passwordInput"
                                                                {...register("password", { required: true })}
                                                                label={t('user.password')}
                                                                type={showPassword ? "text" : "password"}
                                                                error={!!errors.password}
                                                                variant="outlined"
                                                                className="full-width"
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                aria-label="toggle password visibility"
                                                                                onClick={handleClickShowPassword}
                                                                            >
                                                                                {showPassword ? <FontAwesomeIcon icon='eye' /> :
                                                                                    <FontAwesomeIcon icon='eye-slash' />}
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    )
                                                                }} />
                                                        </FormControl>}
                                                </div>
                                            </div>
                                        </div>
                                    </>}

                                {/* Field isActive */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth error={!!errors.isActive}>
                                        <FormControlLabel
                                            {...register("isActive", {})}
                                            label={t('user.isActive')}
                                            control={<Checkbox checked={isCheckedIsactive}
                                                onChange={(_event: React.ChangeEvent<HTMLInputElement>) => {
                                                    setIsCheckedIsactive(_event.target.checked)
                                                    setValue("isActive", _event.target.checked);
                                                }} />}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </CardContent>
                    <CardActions>
                        <div className="pb-4 pt-2 px-md-4 px-2 " style={{ width: '100%', textAlign: 'right' }}>

                            {/* Cancel Button */}
                            <Button variant="outlined"
                                onClick={onCancel}>
                                {t('common.cancel')}
                            </Button>

                            &nbsp;&nbsp;

                            {/* Okay Button */}
                            <LoadingButton loading={isLoading}
                                type="submit"
                                variant="contained"
                                className="bg-custom-gradient">
                                {isEditMode ? t('common.edit') : t('common.add')}
                            </LoadingButton>
                            &nbsp;
                        </div>
                    </CardActions>
                </form>
            </Card>
        </Box>
    );
}

export default UserForm;
