import React, { useEffect } from 'react';
import { useStyles } from './Styles';
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import { post, getAll } from "../../../../../Services/GenericApiService";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { log } from "../../../../../Services/LoggerService";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import { Button, FormControl, TextField, FormControlLabel, Checkbox, FormHelperText, IconButton } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


// @ts-ignore
function QuestionForm({ handleAddEvent, handleCancelEvent, isLoading, isEditMode, recordToUpdate, handleEditEvent }) {
    // css for module
    const classes = useStyles();

    // router object
    const navigate = useNavigate();

    // module(s) for api
    const moduleMain = 'question';

    // linked components API modules (endpoints)
    const moduleQuestiontype = 'questiontype';
    const moduleQuizsection = 'quizsection';


    // translation
    const { t } = useTranslation();

    // UI methods
    const [loading, setLoading] = React.useState(true);
    const [showError, setShowError] = React.useState(false);

    // form data
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm(); // watch is used to get the value e.g watch("name")
    const [checkedField, setCheckedField] = React.useState(-1);
    // form methods

    const onSubmit = (_data: any) => {
        if (!isEditMode) {

            if (checkedField === -1) {
                setShowError(true)
            } else {
                for (const key in _data) {
                    if (deleteOption.length > 0) {
                        if (key.includes("textField_")) {
                            const number = Number(key.split("_")[1]);
                            if (deleteOption.includes(number)) {
                                delete _data["textField_" + number];
                                delete _data["option_" + number];
                            }
                        }
                    }

                }
                handleAddEvent(_data);
            }

        } else {

            const result = { ..._data };
            // Loop through the keys and set option values to false
            for (const key in result) {
                if (key.startsWith('option_')) {
                    if (key === 'option_' + checkedField) {
                        result[key] = true;
                    } else {
                        result[key] = false;
                    }
                }
                if (deleteOption.length > 0) {
                    if (key.includes("textField_")) {
                        const number = Number(key.split("_")[1]);
                        if (deleteOption.includes(number)) {
                            delete result["textField_" + number];
                            delete result["option_" + number];
                        }
                    }
                }

            }
            handleEditEvent(result);
        }
    }

    // data vars


    // ui handlers
    const onCancel = () => {
        handleCancelEvent();
    }

    // fetch dependent data
    const [allQuestiontype, setAllQuestiontype] = React.useState([]);
    const [allQuizsection, setAllQuizsection] = React.useState([]);
    const [allOption, setAllOption] = React.useState<any[]>([]);
    const [deleteOption, setDeleteOption] = React.useState<any[]>([]);

    // Add these state variables inside the component function
    const [textFields, setTextFields] = React.useState([{ id: 0 }]);
    // Add this state variable inside the component function
  
    
    const handleAddTextField = (dataId: any = null) => {
        if (!isEditMode) {
            setTextFields((prevFields) => [...prevFields, { id: prevFields.length }]);

        } else {
            setAllOption((prevFields) => [...prevFields, { id: dataId, isCorrect: false }]);
        }
    };

    const handleDeleteTextField = (idToDelete: any) => {
        if (!isEditMode) {
            setTextFields((prevFields) => prevFields.filter((field) => field.id !== idToDelete));
            setDeleteOption([...deleteOption, idToDelete]);
        } else {
            setAllOption((prevFields) => prevFields.filter((field) => field.id !== idToDelete));
            setDeleteOption([...deleteOption, idToDelete]);
        }

    };
    const handleCheckboxChange = (optionId: any) => {
        if(isEditMode){
            setCheckedField(optionId);
        }
        else{
            setShowError(false)
            setCheckedField(optionId);
        }
       
    };

    const fetch = () => {
        fetchQuestiontype();
        fetchQuizsection();

    }

    // fetch Questiontype
    const fetchQuestiontype = () => {
        setLoading(true); // show loader
        getAll(moduleQuestiontype)
            .then((_res: any) => {
                setAllQuestiontype(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // fetch Quizsection
    const fetchQuizsection = () => {
        setLoading(true); // show loader
        getAll(moduleQuizsection)
            .then((_res: any) => {
                setAllQuizsection(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // hooks
    useEffect(() => {
        fetch();
        if (isEditMode) {
            setAllOption(recordToUpdate?.questionQuestionOption);
            recordToUpdate?.questionQuestionOption.map((item: any) => {
                if (item?.isCorrect === 1) {
                    setCheckedField(item?.id);

                }
            })
            reset(recordToUpdate);


        }
    }, []);

    return (
        <Box sx={{ display: 'flex' }}>
            <Card sx={{ width: '75vw', maxHeight: '90vh', overflowY: 'auto' }}>
                {/* Form start */}
                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <div className="py-2 px-md-4 px-2">
                            <div className="row">

                                <div className="col-md-12 my-2">
                                    {/* Form name */}
                                    <h3 className="pb-2">
                                        {isEditMode ? t('common.edit') : t('common.add')} {t('module.question')}
                                    </h3>

                                </div>



                                {/* Field question */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="questionInput"
                                            {...register("question", { required: true })}
                                            label={t('question.question')}
                                            type={"text"}
                                            error={!!errors.question}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field questionTypeId */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="questionTypeIdLabel">
                                            Question Type
                                        </InputLabel>
                                        {allQuestiontype.length > 0 &&
                                            <Select labelId="questionTypeIdLabel"
                                                id="questionTypeIdSelect"
                                                {...register("questionTypeId", { required: true })}
                                                error={!!errors.questionTypeId}
                                                value={watch('questionTypeId')}
                                                label="questionTypeId">
                                                {
                                                    allQuestiontype.map((el: any) =>
                                                        <MenuItem value={el.id}
                                                            key={el.id}> {el.name}
                                                        </MenuItem>)
                                                }
                                            </Select>
                                        }
                                        {allQuestiontype.length == 0 &&
                                            <>
                                                <Select labelId="questionTypeIdLabel"
                                                    disabled
                                                    id="questionTypeIdSelect"
                                                    {...register("questionTypeId", { required: true })}
                                                    error={!!errors.questionTypeId}
                                                    value={watch('questionTypeId')}
                                                    label="questionTypeId">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>

                                {/* Field quizSectionId */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="quizSectionIdLabel">
                                            Section
                                        </InputLabel>
                                        {allQuizsection.length > 0 &&
                                            <Select labelId="quizSectionIdLabel"
                                                id="quizSectionIdSelect"
                                                {...register("quizSectionId", { required: true })}
                                                error={!!errors.quizSectionId}
                                                value={watch('quizSectionId')}
                                                label="quizSectionId">
                                                {
                                                    allQuizsection.map((el: any) =>
                                                        <MenuItem value={el.id}
                                                            key={el.id}> {el.name}
                                                        </MenuItem>)
                                                }
                                            </Select>
                                        }
                                        {allQuizsection.length == 0 &&
                                            <>
                                                <Select labelId="locationIdLabel"
                                                    disabled
                                                    id="quizSectionIdSelect"
                                                    {...register("quizSectionId", { required: true })}
                                                    error={!!errors.quizSectionId}
                                                    value={watch('quizSectionId')}
                                                    label="quizSectionId">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>


                            </div>

                            <div className="row">

                                {!isEditMode && textFields.map((field, index) => (
                                    <React.Fragment key={field.id}>
                                        <div className="row">
                                            <div className="col-md-8 mt-4">
                                                {/* Field for additional text */}
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id={`textFieldInput_${field.id}`} // Use the 'id' as a part of the ID to make it unique
                                                        {...register(`textField_${field.id}`, { required: true })}
                                                        label={t('question.option')}
                                                        type="text"
                                                        error={!!errors[`textField_${field.id}`]}
                                                        variant="outlined"
                                                        className="full-width"
                                                    />
                                                </FormControl>
                                            </div>

                                            <div className="col-md-2 mt-4 pt-2 px-5">
                                                {/* Checkbox */}
                                                <FormControl fullWidth>
                                                    <FormControlLabel
                                                        {...register(`option_${field.id}`)}
                                                        label={t('question.correct')}
                                                        control={<Checkbox checked={checkedField === field.id}
                                                        onChange={() => handleCheckboxChange(field.id)}
                                                            // onChange={() => {setCheckedField(field.id),setShowError(false)}}
                                                             />}
                                                    />
                                                </FormControl>
                                                {showError && <span className='text-sm custom-error'>Required *</span>}
                                            </div>
                                            
                                            <div className="col-md-2 mt-4" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                {textFields.length > 1 && ( // Show delete button except for the first text field
                                                    <IconButton onClick={() => handleDeleteTextField(field.id)}>
                                                        <FontAwesomeIcon className="delete-btn" icon='trash' />
                                                    </IconButton>
                                                )}

                                            </div>

                                            <div className="row">
                                                <div className="col-md-12 mt-4" style={{ display: 'flex', alignItems: 'start', justifyContent: 'flex-start' }}>
                                                    {index === textFields.length - 1 && ( // Show "Add" button only on the last text field
                                                        <Button variant="contained"
                                                            onClick={handleAddTextField}
                                                            className="bg-custom-gradient">
                                                            {t('question.addMore')}
                                                        </Button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ))}


                                {/* ---------------------------- For Edit --------------------------------- */}

                                {isEditMode && allOption.length > 0 && allOption.map((option, index) => (
                                    <React.Fragment key={option.id}>
                                        <div className="row">
                                            <div className="col-md-8 mt-4">
                                                {/* Field for additional text */}
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id={`textFieldInput_${option['id']}`} // Use the 'id' as a part of the ID to make it unique
                                                        {...register(`textField_${option['id']}`, { required: true })}
                                                        label={t('question.option')}
                                                        type="text"
                                                        defaultValue={option['content']} // Populate the text field with the 'content' value from the option
                                                        error={!!errors[`textField_${option['id']}`]}
                                                        variant="outlined"
                                                        className="full-width"
                                                    />
                                                </FormControl>
                                            </div>

                                            <div className="col-md-2 mt-4 pt-2 px-5">
                                                {/* Checkbox */}
                                                <FormControl fullWidth>
                                                    <FormControlLabel
                                                        id={`option_${option['id']}`}
                                                        {...register(`option_${option['id']}`, {})}
                                                        label={t('question.correct')}
                                                        control={<Checkbox checked={checkedField === option['id']}
                                                            onChange={() => handleCheckboxChange(option['id'])} />}
                                                    />
                                                </FormControl>
                                            </div>

                                            <div className="col-md-2 mt-4" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                {allOption.length > 1 && ( // Show delete button except for the first text field
                                                    <IconButton onClick={() => handleDeleteTextField(option['id'])}>
                                                        <FontAwesomeIcon className="delete-btn" icon='trash' />
                                                    </IconButton>
                                                )}
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12 mt-4" style={{ display: 'flex', alignItems: 'start', justifyContent: 'flex-start' }}>
                                                    {index === allOption.length - 1 && ( // Show "Add" button only on the last text field
                                                        <Button variant="contained" onClick={() => handleAddTextField(allOption.length)} className="bg-custom-gradient">
                                                            {t('question.addMore')}
                                                        </Button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </CardContent>
                    <CardActions>
                        <div className="pb-4 pt-2 px-md-4 px-2 " style={{ width: '100%', textAlign: 'right' }}>

                            {/* Cancel Button */}
                            <Button variant="outlined"
                                onClick={onCancel}>
                                {t('common.cancel')}
                            </Button>

                            &nbsp;&nbsp;

                            {/* Okay Button */}
                            <LoadingButton loading={isLoading}
                                type="submit"
                                variant="contained"
                                className="bg-custom-gradient">
                                {isEditMode ? t('common.edit') : t('common.add')}
                            </LoadingButton>
                            &nbsp;
                        </div>
                    </CardActions>
                </form>
            </Card>
        </Box>
    );
}

export default QuestionForm;
