import React, { useEffect } from 'react';
import { useStyles } from './Styles';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Button, FormControl, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { DatePicker, DateTimePicker } from '@mui/lab';
import { addDays } from 'date-fns';


// @ts-ignore
function QuizForm({ handleAddEvent, handleCancelEvent, isLoading, isEditMode, recordToUpdate, handleEditEvent }) {
    // css for module
    const classes = useStyles();

    // router object
    const navigate = useNavigate();

    // module(s) for api
    const moduleMain = 'quiz';

    // linked components API modules (endpoints)


    // translation
    const { t } = useTranslation();

    // UI methods
    const [loading, setLoading] = React.useState(true);
    const initialDate = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1); // Get tomorrow's date
    const [validFrom, setValidFrom] = React.useState<Date | null>(initialDate);
    const [validTill, setValidTill] = React.useState<Date | null>(tomorrow);

    // form data
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm(); // watch is used to get the value e.g watch("name")

    // form methods
    const onSubmit = (_data: any) => {
        if (!isEditMode) {
            _data.validTill = validTill;
            _data.validFrom = validFrom;
            handleAddEvent(_data);
        } else {
            handleEditEvent(_data);
        }
    }

    // data vars


    // ui handlers
    const onCancel = () => {
        handleCancelEvent();
    }

    // fetch dependent data



    const fetch = () => {

    }



    // hooks
    useEffect(() => {
        fetch();
        if (isEditMode) {
            reset(recordToUpdate);
            setValidFrom(new Date(recordToUpdate.validFrom)); // Convert to Date object
            setValidTill(new Date(recordToUpdate.validTill));

        }
    }, []);


    return (
        <Box sx={{ display: 'flex' }}>
            <Card sx={{ width: '75vw', maxHeight: '90vh', overflowY: 'auto' }}>
                {/* Form start */}
                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <div className="py-2 px-md-4 px-2">
                            <div className="row">

                                <div className="col-md-12 my-2">
                                    {/* Form name */}
                                    <h3 className="pb-2">
                                        {isEditMode ? t('common.edit') : t('common.add')} {t('module.quiz')}
                                    </h3>

                                </div>



                                {/* Field name */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="nameInput"
                                            {...register("name", { required: true })}
                                            label={t('quiz.name')}
                                            type={"text"}
                                            error={!!errors.name}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Valid From */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <DatePicker 
                                            label={t('quiz.validFrom')}
                                            {...register("validFrom", { required: true })}
                                            value={validFrom}
                                            onChange={(newValue) => setValidFrom(newValue)}
                                            disablePast // This will disable dates before today
                                            minDate={new Date()}
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                        />
                                    </FormControl>
                                </div>

                                {/* Valid Till */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <DatePicker 
                                            label={t('quiz.validTill')}
                                            value={validTill}
                                            {...register("validTill", { required: true })}
                                            onChange={(newValue) => {
                                                setValidTill(newValue)
                                                console.log(newValue);
                                            }}
                                            disablePast // This will disable dates before today
                                            minDate={addDays(new Date(), 1)}
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                        />
                                    </FormControl>
                                </div>

                            </div>
                        </div>
                    </CardContent>
                    <CardActions>
                        <div className="pb-4 pt-2 px-md-4 px-2 " style={{ width: '100%', textAlign: 'right' }}>

                            {/* Cancel Button */}
                            <Button variant="outlined"
                                onClick={onCancel}>
                                {t('common.cancel')}
                            </Button>

                            &nbsp;&nbsp;

                            {/* Okay Button */}
                            <LoadingButton loading={isLoading}
                                type="submit"
                                variant="contained"
                                className="bg-custom-gradient">
                                {isEditMode ? t('common.edit') : t('common.add')}
                            </LoadingButton>
                            &nbsp;
                        </div>
                    </CardActions>
                </form>
            </Card>
        </Box>
    );
}

export default QuizForm;
