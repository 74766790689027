// @ts-nocheck
import React, {useEffect, useRef} from 'react';
import Skeleton from '@mui/material/Skeleton';
import Typography, { TypographyProps } from '@mui/material/Typography';

function TextDisplay({label = '', data = '', background = 'dark', isLoading = false}) {

    return (
        <div className={'card shadow-lg bg-' + background}>
            <div className={'alert m-0 p-0 py-2 px-3'}>
                <h6 className={'text-muted'}>
                    {label}
                </h6>
                <Typography
                    variant="h4"
                    className={'fw-bolder text-white'}>
                    {isLoading ? <Skeleton sx={{ bgcolor: '#848884' }} /> : data}
                </Typography>
                {/*<h3 className={'fw-bolder text-white'}>*/}
                {/*    {data}*/}
                {/*</h3>*/}
            </div>
        </div>
    );

}

export default TextDisplay;
