import React, {useEffect} from 'react';
import {useStyles} from './Styles';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import {post, getAll} from "../../../../../Services/GenericApiService";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {log} from "../../../../../Services/LoggerService";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import {Button, FormControl, TextField, FormControlLabel, Checkbox, FormHelperText} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import {DatePicker} from '@mui/lab';
import FileUploadM from "../../../../Helpers/FileUploadM/FileUploadM";


// @ts-ignore
function LocationForm({handleAddEvent, handleCancelEvent, isLoading, isEditMode, recordToUpdate, handleEditEvent}) {
    // css for module
    const classes = useStyles();

    // router object
    const navigate = useNavigate();

    // module(s) for api
    const moduleMain = 'location';

    // linked components API modules (endpoints)
    const moduleRegion = 'region';
const moduleUser = 'user';


    // translation
    const {t} = useTranslation();

    // UI methods
    const [loading, setLoading] = React.useState(true);

    // form data
    const {register, handleSubmit, watch, reset, setValue, formState: {errors}} = useForm(); // watch is used to get the value e.g watch("name")

    // form methods
    const onSubmit = (_data: any) => {
        if (!isEditMode){
            handleAddEvent(_data);
        } else {
            handleEditEvent(_data);
        }
    }

    // data vars
    

    // ui handlers
    const onCancel = () => {
        handleCancelEvent();
    }

    // fetch dependent data
    const [allRegion, setAllRegion] = React.useState([]);
const [allUser, setAllUser] = React.useState([]);



    const fetch = () => {
        fetchRegion();
fetchUser();

    }

        // fetch Region
    const fetchRegion = () => {
        setLoading(true); // show loader
        getAll(moduleRegion)
            .then((_res: any) => {
                setAllRegion(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // fetch User
    const fetchUser = () => {
        setLoading(true); // show loader
        getAll(moduleUser)
            .then((_res: any) => {
                setAllUser(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }



    // hooks
    useEffect(() => {
        fetch();
        if (isEditMode) {
            reset(recordToUpdate);

            
        }
    }, []);


    return (
        <Box sx={{display: 'flex'}}>
            <Card sx={{width: '75vw', maxHeight: '90vh', overflowY: 'auto'}}>
                {/* Form start */}
                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <div className="py-2 px-md-4 px-2">
                            <div className="row">

                                <div className="col-md-12 my-2">
                                    {/* Form name */}
                                    <h3 className="pb-2">
                                        {isEditMode ? t('common.edit') : t('common.add')} {t('module.location')}
                                    </h3>

                                </div>


                                
                                {/* Field name */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="nameInput"
                                                   {...register("name", {required: true})}
                                                   label={t('location.name')}
                                                   type={"text"}
                                                   error={!!errors.name}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field description */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="descriptionInput"
                                                   {...register("description", {required: false})}
                                                   label={t('location.description')}
                                                   type={"text"}
                                                   error={!!errors.description}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field longitude */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="longitudeInput"
                                                   {...register("longitude", {required: false})}
                                                   label={t('location.longitude')}
                                                   type={"text"}
                                                   error={!!errors.longitude}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field latitude */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="latitudeInput"
                                                   {...register("latitude", {required: false})}
                                                   label={t('location.latitude')}
                                                   type={"text"}
                                                   error={!!errors.latitude}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field timezone */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="timezoneInput"
                                                   {...register("timezone", {required: false})}
                                                   label={t('location.timezone')}
                                                   type={"text"}
                                                   error={!!errors.timezone}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field regionId */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="regionIdLabel">
                                            Region
                                        </InputLabel>
                                        {  allRegion.length > 0 &&
                                        <Select labelId="regionIdLabel"
                                                id="regionIdSelect"
                                                {...register("regionId", {required: true})}
                                                error={!!errors.regionId}
                                                value={watch('regionId')}
                                                label="regionId">
                                            {
                                                allRegion.map((el: any) =>
                                                    <MenuItem value={el.id}
                                                              key={el.id}> {el.name}
                                                    </MenuItem>)
                                            }
                                        </Select>
                                        }
                                         { allRegion.length == 0 &&
                                            <>
                                                <Select labelId="locationIdLabel"
                                                        disabled
                                                        id="regionIdSelect"
                                                        {...register("regionId", {required: true})}
                                                        error={!!errors.regionId}
                                                        value={watch('regionId')}
                                                        label="regionId">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>

                                {/* Field supervisorId */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="supervisorIdLabel">
                                            Supervisor
                                        </InputLabel>
                                        {  allUser.length > 0 &&
                                        <Select labelId="supervisorIdLabel"
                                                id="supervisorIdSelect"
                                                {...register("supervisorId", {required: true})}
                                                error={!!errors.supervisorId}
                                                value={watch('supervisorId')}
                                                label="supervisorId">
                                            {
                                                allUser.map((el: any) =>
                                                    <MenuItem value={el.id}
                                                              key={el.id}> {el.email}
                                                    </MenuItem>)
                                            }
                                        </Select>
                                        }
                                         { allUser.length == 0 &&
                                            <>
                                                <Select labelId="locationIdLabel"
                                                        disabled
                                                        id="supervisorIdSelect"
                                                        {...register("supervisorId", {required: true})}
                                                        error={!!errors.supervisorId}
                                                        value={watch('supervisorId')}
                                                        label="supervisorId">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>


                            </div>
                        </div>
                    </CardContent>
                    <CardActions>
                        <div className="pb-4 pt-2 px-md-4 px-2 " style={{width: '100%', textAlign: 'right'}}>

                            {/* Cancel Button */}
                            <Button variant="outlined"
                                    onClick={onCancel}>
                                {t('common.cancel')}
                            </Button>

                            &nbsp;&nbsp;

                            {/* Okay Button */}
                            <LoadingButton loading={isLoading}
                                           type="submit"
                                           variant="contained"
                                           className="bg-custom-gradient">
                                {isEditMode ? t('common.edit') : t('common.add')}
                            </LoadingButton>
                            &nbsp;
                        </div>
                    </CardActions>
                </form>
            </Card>
        </Box>
    );
}

export default LocationForm;
