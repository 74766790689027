import React, { useRef } from 'react';
import { useStyles } from './Styles';
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { patch, post, deleteByIdHard } from "../../../../../Services/GenericApiService";
import { useTranslation } from "react-i18next";
import UserForm from "../UserForm/UserForm";
import { log } from "../../../../../Services/LoggerService";

import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { Button } from "@mui/material";
import AlertM from '../../../../Helpers/AlertM/AlertM';


// @ts-ignore
function UserUpdate({ refreshDataEvent, recordToUpdate, resetEditState }) {
    // css for module
    const classes = useStyles();

    // module(s) for api
    const moduleMain = 'user';
    const moduleTeamuser = 'teamuser/all';
    const moduleTeamuserCreate = 'teamuser';

    // translation
    const { t } = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // ui handlers
    const [open, setOpen] = React.useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [loading, setLoading] = React.useState(false);


    // delay before closing update screen
    const sleep = (milliseconds: number) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    // handle edit record event
    const handleEditEvent = (_formData: any) => {

        setLoading(true); // show loader
        patch(moduleMain, _formData.id, _formData)
            .then(async (_res) => {
                if (_formData.teamId) {
                    deleteUserTeam(_formData.id);
                    addTeamUser(_formData.id,_formData.teamId)
                }
                setLoading(false); // hide loader
                refreshDataEvent(); // refresh data
                handleClose();
                // @ts-ignore
                notifications.current.successAlert(t('common.editSuccess'), '');
                // @ts-ignore
                await sleep(process.env.REACT_APP_UPDATE_NOTIFICATION_TIMEOUT_MS || 1000);
                resetEdit();
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                resetEdit();
            })
    }

    // handle cancel event
    const handleCancelEvent = () => {
        handleClose();
        resetEdit();
    }


    // reset isEdit to false in main component
    const resetEdit = () => {
        resetEditState(true);
    }

    // delete all user team
    const deleteUserTeam = (userId: any) => {
        deleteByIdHard(moduleTeamuser, userId)
            .then(async (_res) => {
            })
            .catch(_err => {
                log(_err)
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                resetEdit();
            })
    }

    // handle add user id and team id in teamuser table 
    const addTeamUser = (userId: any, teamId: any) => {
        setLoading(true); // show loader
        let obj = {
            userId: userId,
            teamId: teamId
        }
        post(moduleTeamuserCreate, obj)
            .then(_res => {
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
            })
    }


    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                onBackdropClick={handleCancelEvent}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box className={classes.modal}>
                        <UserForm handleCancelEvent={handleCancelEvent}
                            handleAddEvent={() => { }}
                            isEditMode={true}
                            handleEditEvent={handleEditEvent}
                            recordToUpdate={recordToUpdate}
                            isLoading={loading} />
                    </Box>
                </Fade>
            </Modal>


            {/* Alerts */}
            <AlertM ref={notifications} />

        </div>
    );
}

export default UserUpdate;
