import React, { useRef } from 'react';
import { useStyles } from './Styles';
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { deleteByIdHard, patch, post } from "../../../../../Services/GenericApiService";
import { useTranslation } from "react-i18next";
import BlogForm from "../BlogForm/BlogForm";
import { log } from "../../../../../Services/LoggerService";

import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { Button } from "@mui/material";
import AlertM from '../../../../Helpers/AlertM/AlertM';


// @ts-ignore
function BlogUpdate({ refreshDataEvent, recordToUpdate, resetEditState, isNotes}) {

    // css for module
    const classes = useStyles();

    // module(s) for api
    const moduleMain = 'blog';
    const moduleBlogquiz = 'blogquiz';
    const moduleDeleteBlogquiz = 'blogquiz/blog';
    
    // translation
    const { t } = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // ui handlers
    const [open, setOpen] = React.useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [loading, setLoading] = React.useState(false);


    // delay before closing update screen
    const sleep = (milliseconds: number) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    // handle edit record event
    const handleEditEvent = (_formData: any) => {
        _formData.isNotes = isNotes ? true : false;
        setLoading(true); // show loader
        patch(moduleMain, _formData.id, _formData)
            .then(async (_res) => {
                if (!isNotes) {
                    updateBlogQuiz(_formData.id, _formData?.blogBlogQuiz[0]?.quizId);
                } else {
                    setLoading(false); // hide loader
                    refreshDataEvent(); // refresh data
                    handleClose();
                    // @ts-ignore
                    notifications.current.successAlert(t('common.editSuccess'), '');
                    // @ts-ignore
                    await sleep(process.env.REACT_APP_UPDATE_NOTIFICATION_TIMEOUT_MS || 1000);
                    resetEdit();
                }
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                resetEdit();
            })
    }

    // handle cancel event
    const handleCancelEvent = () => {
        handleClose();
        resetEdit();
    }


    // reset isEdit to false in main component
    const resetEdit = () => {
        resetEditState(true);
    }

    const updateBlogQuiz = (blogId: any, quizId: any) => {
        let obj = {
            quizId: quizId
        };
        deleteByIdHard(moduleDeleteBlogquiz, blogId)
            .then(async (_res) => {
                let blogQuizObj = {
                    blogId: blogId,
                    quizId: quizId
                };
                createBlogQuiz(blogQuizObj);
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                resetEdit();
            })
    }

    const createBlogQuiz = (_obj: any) => {
        post(moduleBlogquiz,_obj)
        .then(async (_res) => {
            setLoading(false); // hide loader
            refreshDataEvent(); // refresh data
            handleClose();
            // @ts-ignore
            notifications.current.successAlert(t('common.editSuccess'), '');
            // @ts-ignore
            await sleep(process.env.REACT_APP_UPDATE_NOTIFICATION_TIMEOUT_MS || 1000);
            resetEdit();
        })
        .catch(_err => {
            log(_err)
            setLoading(false); // hide loader
            // @ts-ignore
            notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
            resetEdit();
        })
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                onBackdropClick={handleCancelEvent}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box className={classes.modal}>
                        <BlogForm handleCancelEvent={handleCancelEvent}
                            handleAddEvent={() => { }}
                            isEditMode={true}
                            isNotes={isNotes}
                            handleEditEvent={handleEditEvent}
                            recordToUpdate={recordToUpdate}
                            isLoading={loading} />
                    </Box>
                </Fade>
            </Modal>


            {/* Alerts */}
            <AlertM ref={notifications} />

        </div>
    );
}

export default BlogUpdate;
