export const bgColor = [
    'rgba(168, 125, 50, 0.5)',
    'rgba(212, 227, 50, 0.5)',
    'rgba(255, 217, 102, 0.5)',
    'rgba(255, 199, 26, 0.5)',
    'rgba(230, 172, 0, 0.5)',
    'rgba(26, 255, 255, 0.5)',
    'rgba(102, 255, 51,0.5)',
    'rgba(102, 255, 51, 0.5)',
    'rgba(0, 204, 204, 0.5)',
    'rgba(255, 163, 102, 0.5)',
    'rgba(204, 163, 0, 0.5)',
    'rgba(230, 184, 0, 0.5)',
    'rgba(255, 209, 26, 0.5)',
    'rgba(181, 130, 155, 0.5)',
    'rgba(0, 204, 163, 0.5)',
    'rgba(0, 230, 184, 0.5)',
    'rgba(0, 255, 85, 0.5)',
    'rgba(51, 255, 119, 0.5)',
    'rgba(102, 255, 153, 0.5)',
    'rgba(255, 187, 51, 0.5)',
    'rgba(255, 212, 128, 0.5)',
    'rgba(255, 230, 179, 0.5)',
    'rgba(30, 144, 255, 0.5)',
    'rgba(135, 206, 235, 0.5)',
    'rgba(70, 130, 180, 0.5)',
    'rgba(176, 224, 230, 0.5)',
    'rgba(95, 158, 160, 0.5)',
    'rgba(143, 188, 143, 0.5)',
    'rgba(34, 139, 34, 0.5)',
    'rgba(154, 205, 50, 0.5)',
    'rgba(0, 128, 0, 0.5)',
    'rgba(107, 142, 35, 0.5)',
    'rgba(127, 255, 0, 0.5)',
    'rgba(173, 255, 47, 0.5)',
    'rgba(46, 139, 87, 0.5)',
    'rgba(60, 179, 113, 0.5)',
    'rgba(32, 178, 170, 0.5)',
    'rgba(0, 255, 255, 0.5)',
    'rgba(72, 209, 204, 0.5)',
    'rgba(64, 224, 208, 0.5)',
    'rgba(175, 238, 238, 0.5)',
    'rgba(100, 149, 237, 0.5)',
    'rgba(0, 0, 205, 0.5)',
    'rgba(138, 43, 226, 0.5)',
    'rgba(148, 0, 211, 0.5)',
    'rgba(255, 105, 180, 0.5)',
    'rgba(199, 21, 133, 0.5)',
    'rgba(220, 20, 60, 0.5)',
    'rgba(255, 0, 0, 0.5)',
    'rgba(255, 69, 0, 0.5)',
    'rgba(255, 127, 80, 0.5)',
    'rgba(205, 92, 92, 0.5)',
    'rgba(128, 0, 0, 0.5)',
    'rgba(165, 42, 42, 0.5)'
];

export const borderColor = [
    'rgba(168, 125, 50, 1)',
    'rgba(212, 227, 50, 1)',
    'rgba(255, 217, 102, 1)',
    'rgba(255, 199, 26, 1)',
    'rgba(230, 172, 0, 1)',
    'rgba(26, 255, 255, 1)',
    'rgba(102, 255, 51,1)',
    'rgba(102, 255, 51, 1)',
    'rgba(0, 204, 204, 1)',
    'rgba(255, 163, 102, 1)',
    'rgba(204, 163, 0, 1)',
    'rgba(230, 184, 0, 1)',
    'rgba(255, 209, 26, 1)',
    'rgba(181, 130, 155, 1)',
    'rgba(0, 204, 163, 1)',
    'rgba(0, 230, 184, 1)',
    'rgba(0, 255, 85, 1)',
    'rgba(51, 255, 119, 1)',
    'rgba(102, 255, 153, 1)',
    'rgba(255, 187, 51, 1)',
    'rgba(255, 212, 128, 1)',
    'rgba(255, 230, 179, 1)',
    'rgba(30, 144, 255, 1)',
    'rgba(135, 206, 235, 1)',
    'rgba(70, 130, 180, 1)',
    'rgba(176, 224, 230, 1)',
    'rgba(95, 158, 160, 1)',
    'rgba(143, 188, 143, 1)',
    'rgba(34, 139, 34, 1)',
    'rgba(154, 205, 50, 1)',
    'rgba(0, 128, 0, 1)',
    'rgba(107, 142, 35, 1)',
    'rgba(127, 255, 0, 1)',
    'rgba(173, 255, 47, 1)',
    'rgba(46, 139, 87, 1)',
    'rgba(60, 179, 113, 1)',
    'rgba(32, 178, 170, 1)',
    'rgba(0, 255, 255, 1)',
    'rgba(72, 209, 204, 1)',
    'rgba(64, 224, 208, 1)',
    'rgba(175, 238, 238, 1)',
    'rgba(100, 149, 237, 1)',
    'rgba(0, 0, 205, 1)',
    'rgba(138, 43, 226, 1)',
    'rgba(148, 0, 211, 1)',
    'rgba(255, 105, 180, 1)',
    'rgba(199, 21, 133, 1)',
    'rgba(220, 20, 60, 1)',
    'rgba(255, 0, 0, 1)',
    'rgba(255, 69, 0, 1)',
    'rgba(255, 127, 80, 1)',
    'rgba(205, 92, 92, 1)',
    'rgba(128, 0, 0, 1)',
    'rgba(165, 42, 42, 1)'
];