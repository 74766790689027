
/** Maps user against their IDs */
const user = {
    safetyAdmin: 1,
    safetyCoordinator: 2,
    user: 3
}

export const accessGroups: any = {
    // input: {
    //     /** Access Group to view All teams shifts sites */
    //     edit: [user.superAdmin, user.admin, user.siteManager]
    // },
    // teamShiftSites: {
    //     /** Access Group to Edit Input Data */
    //     all: [user.superAdmin, user.admin],
    // },
    // user: {
    //     /** Access Group to edit other users */
    //     edit: [user.superAdmin, user.admin]
    // },
    routes: {
        logout: [user.safetyAdmin, user.safetyCoordinator, user.user],
        dashboard: [user.safetyAdmin, user.safetyCoordinator, user.user],
        user: [user.safetyAdmin],
        role: [user.safetyAdmin],
        teams: [user.safetyAdmin],
        quizbuilder: [user.safetyAdmin, user.safetyCoordinator],
        quizscore: [user.safetyAdmin],
        record: [user.safetyAdmin, user.safetyCoordinator],
        touchconfiguration: [user.safetyAdmin, user.safetyCoordinator],
        country: [user.safetyAdmin, user.safetyCoordinator,user.user],
        region: [user.safetyAdmin, user.safetyCoordinator,user.user],
        location: [user.safetyAdmin, user.safetyCoordinator,user.user],
        blog: [user.safetyAdmin],
        notes: [user.safetyAdmin]
    }
}
