import React, { useEffect } from 'react';
import { useStyles } from './Styles';
import { getAll } from "../../../../../Services/GenericApiService";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { log } from "../../../../../Services/LoggerService";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import { Button, FormControl, TextField, FormHelperText } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Select from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import FileUploadM from "../../../../Helpers/FileUploadM/FileUploadM";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

// @ts-ignore
function BlogForm({ handleAddEvent, handleCancelEvent, isLoading, isEditMode, isNotes, recordToUpdate, handleEditEvent }) {

    // css for module
    const classes = useStyles();

    // router object
    const navigate = useNavigate();

    // module(s) for api
    const moduleMain = 'blog';
    const editorStates = EditorState.createEmpty();

    // linked components API modules (endpoints)
    const moduleUser = 'user';
    const moduleQuiz = 'quiz';

    // translation
    const { t } = useTranslation();

    // UI methods
    const [loading, setLoading] = React.useState(true);

    // form data
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm(); // watch is used to get the value e.g watch("name")

    // form methods
    const onSubmit = (_data: any) => {
        if (!isEditMode) {
            const currentContent = editorState.getCurrentContent();
            const contentStateAsRaw = convertToRaw(currentContent);
            const html = contentStateToHtml(contentStateAsRaw); // Define this function
            _data.content = html;
            handleAddEvent(_data);
        } else {
            const currentContent = editorState.getCurrentContent();
            const contentStateAsRaw = convertToRaw(currentContent);
            const html = contentStateToHtml(contentStateAsRaw); // Define this function
            _data.content = html;
            handleEditEvent(_data);
        }
    }

    const contentStateToHtml = (contentState: any) => {
        return draftToHtml(contentState);
    };

    const setHtmlContentInEditor = (html: any) => {
        const blocksFromHtml = convertFromHTML(html);
        const contentState = ContentState.createFromBlockArray(
            blocksFromHtml.contentBlocks,
            blocksFromHtml.entityMap
        );
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
    };

    // data vars


    // ui handlers
    const onCancel = () => {
        handleCancelEvent();
    }

    // fetch dependent data
    const [allUser, setAllUser] = React.useState([]);
    const [allQuiz, setAllQuiz] = React.useState([]);
    const [editorState, setEditorState] = React.useState(editorStates);

    const onEditorStateChange = (newEditorState: any) => {
        setEditorState(newEditorState);
    };
    const fetch = () => {
        fetchUser();
        fetchQuiz();
    }

    // fetch User
    const fetchUser = () => {
        setLoading(true); // show loader
        getAll(moduleUser)
            .then((_res: any) => {
                setAllUser(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // fetch Quiz
    const fetchQuiz = () => {
        setLoading(true); // show loader
        getAll(moduleQuiz)
            .then((_res: any) => {
                setAllQuiz(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }


    // hooks
    useEffect(() => {
        fetch();
        if (isEditMode) {
            setHtmlContentInEditor(recordToUpdate.content);
            reset(recordToUpdate);
        }
    }, []);

    return (
        <Box sx={{ display: 'flex' }}>
            <Card sx={{ width: '75vw', maxHeight: '90vh', overflowY: 'auto' }}>
                {/* Form start */}
                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <div className="py-2 px-md-4 px-2">
                            <div className="row">

                                <div className="col-md-12 my-2">
                                    {/* Form name */}
                                    <h3 className="pb-2">
                                        {!isNotes
                                            ? isEditMode
                                                ? t('common.edit')
                                                : t('common.add')
                                            : isEditMode
                                                ? t('common.edit')
                                                : t('common.add')}{' '}
                                        {!isNotes ? t('module.blog') : t('module.notes')}
                                    </h3>

                                </div>

                                {/* Field title */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="titleInput"
                                            {...register("title", { required: true })}
                                            label={t('blog.title')}
                                            type={"text"}
                                            error={!!errors.title}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field authorId */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="authorIdLabel">
                                            Author
                                        </InputLabel>
                                        {allUser.length > 0 &&
                                            <Select labelId="authorIdLabel"
                                                id="authorIdSelect"
                                                {...register("authorId", { required: true })}
                                                error={!!errors.authorId}
                                                value={watch('authorId')}
                                                label="authorId">
                                                {
                                                    allUser.map((el: any) =>
                                                        <MenuItem value={el.id}
                                                            key={el.id}> {el.email}
                                                        </MenuItem>)
                                                }
                                            </Select>
                                        }
                                        {allUser.length == 0 &&
                                            <>
                                                <Select labelId="locationIdLabel"
                                                    disabled
                                                    id="authorIdSelect"
                                                    {...register("authorId", { required: true })}
                                                    error={!!errors.authorId}
                                                    value={watch('authorId')}
                                                    label="authorId">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>


                                {/* Field quizId */}
                                {!isNotes &&
                                    <div className="col-md-6 mt-4">
                                        <FormControl fullWidth={true}>
                                            <InputLabel id="quizIdLabel">
                                                Quiz
                                            </InputLabel>
                                            {allQuiz.length > 0 &&
                                                <Select labelId="quizIdLabel"
                                                    id="quizIdSelect"
                                                    {...register("blogBlogQuiz[0].quizId", { required: false })}
                                                    error={!!errors.quizId}
                                                    value={watch('blogBlogQuiz[0].quizId')}
                                                    label="quizId">
                                                    {
                                                        allQuiz.map((el: any) =>
                                                            <MenuItem value={el.id}
                                                                key={el.id}> {el.name}
                                                            </MenuItem>)
                                                    }
                                                </Select>
                                            }
                                            {allQuiz.length == 0 &&
                                                <>
                                                    <Select labelId="locationIdLabel"
                                                        disabled
                                                        id="quizIdSelect"
                                                        {...register("quizId", { required: false })}
                                                        error={!!errors.quizId}
                                                        value={watch('quizId')}
                                                        label="quizId">
                                                        <MenuItem value={''}>
                                                            No Data
                                                        </MenuItem>
                                                    </Select>
                                                    <FormHelperText>
                                                        No Data
                                                    </FormHelperText>
                                                </>
                                            }
                                        </FormControl>
                                    </div>
                                }

                                {/* Attachment */}
                                <div className="col-md-12 mt-4">
                                    <FormControl fullWidth={true}>
                                        <FileUploadM />
                                    </FormControl>
                                </div>

                                {/* Field content */}
                                <div className="col-md-12 mt-4">
                                    <Editor
                                        editorState={editorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={onEditorStateChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </CardContent>
                    <CardActions>
                        <div className="pb-4 pt-2 px-md-4 px-2 " style={{ width: '100%', textAlign: 'right' }}>

                            {/* Cancel Button */}
                            <Button variant="outlined"
                                onClick={onCancel}>
                                {t('common.cancel')}
                            </Button>

                            &nbsp;&nbsp;

                            {/* Okay Button */}
                            <LoadingButton loading={isLoading}
                                type="submit"
                                variant="contained"
                                className="bg-custom-gradient">
                                {isEditMode ? t('common.edit') : t('common.add')}
                            </LoadingButton>
                            &nbsp;
                        </div>
                    </CardActions>
                </form>
            </Card>
        </Box>
    );
}

export default BlogForm;
