import React, {useRef} from 'react';
import {useStyles} from './Styles';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {deleteByIdHard, patch, post} from "../../../../../Services/GenericApiService";
import {useTranslation} from "react-i18next";
import QuestionForm from "../QuestionForm/QuestionForm";
import {log} from "../../../../../Services/LoggerService";

import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import {Button} from "@mui/material";
import AlertM from '../../../../Helpers/AlertM/AlertM';


// @ts-ignore
function QuestionUpdate({refreshDataEvent, recordToUpdate, resetEditState}) {
    // css for module
    const classes = useStyles();

    // module(s) for api
    const moduleMain = 'question';
    const optionModule = 'questionoption/bulk';
    const deleteBulkOption = 'questionoption/questionId'

    // translation
    const { t } = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // ui handlers
    const [open, setOpen] = React.useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [loading, setLoading] = React.useState(false);


    // delay before closing update screen
    const sleep = (milliseconds: number) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    // handle edit record event
    const handleEditEvent = (_formData: any) => {
        setLoading(true); // show loader
        deleteOption(_formData.id);
        patch(moduleMain, _formData.id, _formData)
            .then(async (_res) => {
                addOptions(_formData)
                setLoading(false); // hide loader
                refreshDataEvent(); // refresh data
                handleClose();
                // @ts-ignore
                notifications.current.successAlert(t('common.editSuccess'), '');
                // @ts-ignore
                await sleep(process.env.REACT_APP_UPDATE_NOTIFICATION_TIMEOUT_MS || 1000);
                resetEdit();
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                resetEdit();
            })
    }

    
    // delete all option
    const deleteOption = (questionId: any) => {
        deleteByIdHard (deleteBulkOption, questionId)
            .then(async (_res) => {
            })
            .catch(_err => {
                log(_err)
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                resetEdit();
            })
    }

    // handle add record event
    const addOptions = (data: any) => {
        let obj = {}; 
        let questionId = data?.id;
        const arr = [];
        // Loop through the keys in the data object
        for (const key in data) {
            if (key.startsWith("textField")) {
                // Extract the number following the underscore from textField key
                const index = parseInt(key.split("_")[1]);

                // Create an object with "content" and "isCorrect" properties
                const item = {
                    content: data[key],
                    questionId: questionId,
                    isCorrect: data[`option_${index}`],
                };

                // Add the object to the "data" array in obj
                arr.push(item);
            }
        }
        obj = {"data": arr};
        addOption(obj);
    }

    // handle add option event
    const addOption = (_data: any) => {
        post(optionModule, _data)
            .then(_res => {
            })
            .catch(_err => {
                log(_err)
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
            })
    }

    // handle cancel event
    const handleCancelEvent = () => {
        handleClose();
        resetEdit();
    }

    // reset isEdit to false in main component
    const resetEdit = () => {
        resetEditState(true);
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                onBackdropClick={handleCancelEvent}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box className={classes.modal}>
                        <QuestionForm handleCancelEvent={handleCancelEvent}
                                      handleAddEvent={() => {}}
                                      isEditMode={true}
                                      handleEditEvent={handleEditEvent}
                                      recordToUpdate={recordToUpdate}
                                      isLoading={loading}/>
                    </Box>
                </Fade>
            </Modal>


           {/* Alerts */}
            <AlertM ref={notifications}/>

        </div>
    );
}

export default QuestionUpdate;
