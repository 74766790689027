import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    register: {
                        tagline: 'Register with email',
                        haveAccount: 'Already have an account?',
                        createSuccess: 'Account Created',
                        creationMailSent: 'An activation email has been sent to you,'
                    },
                    login: {
                        tagline: 'Enter your email and password to login',
                        dontHaveAccount: 'Don\'t have an account?',
                        loginFailed: 'Login Failed',
                        invalidCredentials: 'Invalid login credentials'
                    },
                    form: {
                        email: 'Email',
                        password: 'Password'
                    },
                    common: {
                        add: 'Add',
                        edit: 'Update',
                        delete: 'Delete',
                        cancel: 'Cancel',
                        view: 'View',
                        success: 'Success',
                        allRecords: 'All Records',
                        recordDetails: 'Record Details',
                        tryAgain: 'Please try again later',
                        somethingWentWrong: 'Something went wrong',
                        createSuccess: 'Record created successfully',
                        editSuccess: 'Record updated successfully',
                        deleteSuccess: 'Record deleted successfully',
                        genericSuccess: 'Operation completed successfully',
                    },
                    module: {
                        register: 'Register',
                        login: 'Login',
                                                user: 'Users',
                                                quiz: 'Quiz',
                                                quizAddUpdate: "Add or Update Quiz",
                                                quizsection: 'Quiz Section',
                                                quizsectionAddUpdate: "Add or Update Quiz Section",
                                                quizuser: 'Quiz User',
                                                questiontype: 'Question Type',
                                                questiontypeAddUpdate: "Add or Update Quiz Type",
                                                question: 'Question',
                                                questionAddUpdate: "Add or Update Question",
                                                questionoption: 'Question Option',
                                                quizanswer: 'Quiz Answer',
                                                recordtype: 'Touch Type',
                                                record: 'Touches',
                                                recordcategory: 'Touch Categories',
                                                country: 'Country',
                                                region: 'Region',
                                                location: 'Site',
                                                quizscore: 'Quiz Score',
                                                blog: 'Bulletin',
                                                blogquiz: 'Bulletin Quiz',
                                                notes: 'Notes',
                                                role: 'Roles',
                                                team: 'Teams',
                        // add new module here %TEMPLATE_MODULE_NAME%
                    },
                    role: {
                        title: 'Title',
                    },
                    team: {
                        name: 'Name',
                    },
                    user: {
                        firstName: 'First Name',
                        middleName: 'Middle Name',
                        lastName: 'Last Name',
                        email: 'Email',
                        contact: 'Contact',
                        roleId: 'Role',
                        team: 'Team',
                        isActive: 'Active',
                        isMicrosoftLinked: 'Microsoft Account',
                        isGoogleLinked: 'Google Account',
                        password: 'Password',
                        token: 'Token',
                        lastLogin: 'Last Login',
                        deviceId: 'Device ID',
},
                    quiz: {
                        name: 'Name',
                        validFrom: 'Valid From',
                        validTill: 'Valid Till'
},
                    quizsection: {
                        quizId: 'Quiz',
                        name: 'Name',
},
                    quizuser: {
                        quizId: 'Quiz',
},
                    questiontype: {
                        name: 'Question Type',
},
                    question: {
                        question: 'Question',
                        questionTypeId: 'Question Type',
                        quizSectionId: 'Section',
                        correct: 'Correct',
                        addMore: 'Add Option',
                        option: 'Option',
                        options: 'Options'
},
                    questionoption: {
                        content: 'Content',
                        questionId: 'Question',
                        isCorrect: 'Correct',
},
                    quizanswer: {
                        questionId: 'Question',
                        userId: 'User',
                        selectedOptionId: 'Selected Option',
},
                    recordtype: {
                        name: 'Name',
                        description: 'Description',
},
                    record: {
                        comments: 'Comments',
                        recordTypeId: 'Touch Type',
                        recordCategory: 'Touch Category',
                        locationId: 'Sites',
                        userId: 'User',
                        mediaFile: 'Attached Files',
                        touchOption: 'Touch Category Option',
},
                    recordcategory: {
                        score: 'Score',
                        narrative: 'Narrative',
                        name: 'Name',
                        recordTypeId: 'Record Type',
},
                    country: {
                        name: 'Name',
},
                    region: {
                        name: 'Name',
                        countryId: 'Country',
},
                    location: {
                        name: 'Name',
                        description: 'Description',
                        longitude: 'Longitude',
                        latitude: 'Latitude',
                        timezone: 'Timezone',
                        regionId: 'Region',
                        supervisorId: 'Supervisor',
},
                    quizscore: {
                        userId: 'User',
                        quizId: 'Quiz',
                        totalCorrect: 'Correct',
                        totalWrong: 'Wrong',
},
                    blog: {
                        title: 'Title',
                        content: 'Content',
                        authorId: 'Author',
                        mediaFile: 'Attached Files',
                        quiz: 'Quiz'
},
                    blogquiz: {
                        blogId: 'Blog',
                        bulletin: 'Bulletin',
                        quizId: 'Quiz',
},
                    recordCategoryOption: {
                        recordCategoryOption: 'Touch Category Option',
                        touchOption: 'Touch Category Option',
                        touchCategory: 'Touch Category'
}// add new module screen items here %TEMPLATE_MODULE_SCREEN_ITEMS%
                }
            },
            de: {
                translation: {
                    description: {
                        part1: 'Ändere <1>src/App.js</1> und speichere um neu zu laden.',
                        part2: 'Lerne React'
                    }
                }
            }
        }
    });

export default i18n;
