// @ts-nocheck

import React, {useEffect, useState, useRef} from 'react';
import '../../../../index.scss';
import {useTranslation} from "react-i18next";

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MUIDataTable from "mui-datatables";
import {Button, IconButton, Skeleton} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import {library} from '@fortawesome/fontawesome-svg-core';
import Tooltip from '@mui/material/Tooltip';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function Privacypolicy() {

    // translation
    const {t} = useTranslation();

    // child ref for alert
    const notifications = useRef();


    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>

            {/* Content */}
            <Box component="main" >

                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    {/*<a className="navbar-brand" href="#">Navbar</a>*/}
                    {/*<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"*/}
                    {/*        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">*/}
                    {/*    <span className="navbar-toggler-icon"></span>*/}
                    {/*</button>*/}
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item active">
                                <a className="nav-link " href="https://hailosafetytouch.bbarray.com/dashboard">CMS</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link " href="https://hailosafetytouch.bbarray.com/privacy">Privacy Policy</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active" href="https://hailosafetytouch.bbarray.com/terms">Terms & Conditions</a>
                            </li>
                        </ul>
                    </div>
                </nav>

                <div className="p-3">
                    <h1>Terms of Use</h1>
                    <p>Last updated: 27th July 2023</p>
                    <p>Welcome to Hailo Safety Touch (“the App”). By accessing or using the App, you agree to be bound by the following Terms of Use. Please read these terms carefully before using the App. If you do not agree with any part of these terms, please refrain from using the App.</p>
                    <h2>1. Acceptance of Terms</h2>
                    <p>By accessing or using the App, you affirm that you are at least 18 years old and capable of entering into a legally binding agreement. If you are using the App on behalf of an organization, you represent and warrant that you have the authority to bind that organization to these Terms of Use.</p>
                    <h2>2. App Usage</h2>
                    <p>Hailo Safety Touch is intended for use on oil rigs to register safety touches and environmental touches. You are responsible for maintaining the confidentiality of your account and ensuring that your account details are accurate and up-to-date.</p>
                    <h2>3. User Conduct</h2>
                    <p>You agree to use the App in compliance with all applicable laws and regulations. When using the App, you must not:</p>
                    <ul>
                        <li>Violate any applicable laws or regulations.</li>
                        <li>Infringe upon the rights of others, including privacy or intellectual property rights.</li>
                        <li>Engage in any illegal, abusive, or harmful activities.</li>
                        <li>Attempt to gain unauthorized access to the App or its systems.</li>
                    </ul>
                    <h2>4. Data and Privacy</h2>
                    <p>The use of personal data collected by the App is governed by our Privacy Policy. By using the App, you consent to the collection, processing, and sharing of your data as described in the Privacy Policy.</p>
                    <h2>5. Intellectual Property</h2>
                    <p>All content and materials available on the App, including but not limited to text, graphics, logos, and images, are the property of BBA Information Technology LLC and protected by applicable intellectual property laws. You may not reproduce, modify, or distribute any content from the App without prior written permission from BBA Information Technology LLC.</p>
                    <h2>6. Disclaimer of Warranties</h2>
                    <p>The App is provided on an “as is” and “as available” basis. We make no warranties or representations of any kind, express or implied, regarding the App’s accuracy, reliability, or availability. Your use of the App is at your own risk.</p>
                    <h2>7. Limitation of Liability</h2>
                    <p>In no event shall BBA Information Technology LLC or its affiliates be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or in connection with your use of the App.</p>
                    <h2>8. Indemnification</h2>
                    <p>You agree to indemnify and hold harmless BBA Information Technology LLC and its officers, employees, and affiliates from any claims, damages, or losses resulting from your violation of these Terms of Use or your use of the App.</p>
                    <h2>9. Changes to the Terms</h2>
                    <p>We reserve the right to update or modify these Terms of Use at any time without prior notice. Continued use of the App after any changes constitutes your acceptance of the revised Terms.</p>
                    <h2>10. Termination</h2>
                    <p>BBA Information Technology LLC may, at its discretion, suspend or terminate your access to the App if you violate these Terms of Use or for any other reason without liability.</p>
                    <h2>11. Governing Law</h2>
                    <p>These Terms of Use shall be governed by and construed in accordance with the laws of the United Arab Emirates, without regard to its conflict of laws principles.</p>
                    <h2>12. Contact Information</h2>
                    <p>If you have any questions or concerns about these Terms of Use or the App, please contact us at:</p>
                    <ul>
                        <li><strong>Contact Email:</strong> contact@bbarray.com</li>
                        <li><strong>Company Name:</strong> BBA Information Technology LLC</li>
                        <li><strong>Company Address:</strong> 407 Ibn Battuta Gate, Jebel Ali Village, Dubai, UAE</li>
                    </ul>
                </div>

            </Box>


        </Box>
    );
}

export default Privacypolicy;
