import React, { useEffect } from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";

// Import components
import Landing from "../Components/Screens/Landing/Landing";
import Login from "../Components/Screens/Authentication/Login/Login";
import Register from "../Components/Screens/Authentication/Register/Register";
import Home from "../Components/Screens/Dashboard/Home/Home"
import Logout from "../Components/Screens/Authentication/Logout/Logout";
import {RootStateOrAny, useSelector} from "react-redux";
import {useTranslation, Trans} from 'react-i18next';
import {AuthGuard} from "./Guards/AuthGuard/AuthGuard";
import {ThemeProvider, createTheme} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import QuizbuilderMain from "../Components/Screens/Dashboard/Quizbuilder/QuizbuilderMain/QuizbuilderMain";

import UserMain from "../Components/Screens/Dashboard/User/UserMain/UserMain";
import QuizMain from "../Components/Screens/Dashboard/Quiz/QuizMain/QuizMain";
import QuizsectionMain from "../Components/Screens/Dashboard/Quizsection/QuizsectionMain/QuizsectionMain";
import QuizuserMain from "../Components/Screens/Dashboard/Quizuser/QuizuserMain/QuizuserMain";
import QuestiontypeMain from "../Components/Screens/Dashboard/Questiontype/QuestiontypeMain/QuestiontypeMain";
import QuestionMain from "../Components/Screens/Dashboard/Question/QuestionMain/QuestionMain";
import QuestionoptionMain from "../Components/Screens/Dashboard/Questionoption/QuestionoptionMain/QuestionoptionMain";
import QuizanswerMain from "../Components/Screens/Dashboard/Quizanswer/QuizanswerMain/QuizanswerMain";
import RecordtypeMain from "../Components/Screens/Dashboard/Recordtype/RecordtypeMain/RecordtypeMain";
import RecordMain from "../Components/Screens/Dashboard/Record/RecordMain/RecordMain";
import RecordcategoryMain from "../Components/Screens/Dashboard/Recordcategory/RecordcategoryMain/RecordcategoryMain";
import CountryMain from "../Components/Screens/Dashboard/Country/CountryMain/CountryMain";
import RegionMain from "../Components/Screens/Dashboard/Region/RegionMain/RegionMain";
import LocationMain from "../Components/Screens/Dashboard/Location/LocationMain/LocationMain";
import QuizscoreMain from "../Components/Screens/Dashboard/Quizscore/QuizscoreMain/QuizscoreMain";
import BlogMain from "../Components/Screens/Dashboard/Blog/BlogMain/BlogMain";
import BlogquizMain from "../Components/Screens/Dashboard/Blogquiz/BlogquizMain/BlogquizMain";
import TouchConfiguration from "../Components/Screens/Dashboard/Touchconfiguration/TouchConfiguration";
import Privacypolicy from "../Components/Screens/Dashboard/Termsandconditions/Privacypolicy";
import Terms from "../Components/Screens/Dashboard/Termsandconditions/Terms";
import RoleMain from '../Components/Screens/Dashboard/Role/RoleMain/RoleMain';
import TeamMain from '../Components/Screens/Dashboard/Team/TeamMain/TeamMain';

// %TEMPLATE_IMPORT_COMPONENT%



















function Router() {
    const {t, i18n} = useTranslation();

    // get current language
    // const language = useSelector(
    //     (state: RootStateOrAny) => state.global.global.language,
    // );

    // switch language here
    // i18n.changeLanguage('en')

    // get current theme
    const theme = useSelector(
        (state: RootStateOrAny) => state.global.global.theme,
    );


    const darkTheme = createTheme({
        palette: {
            mode: theme,
            primary: {
                main: "rgb(245, 130, 32)",
                contrastText: "#fff" //button text white instead of black to support dark theme
            }
        },
        components: {
            MuiButtonBase: {
                defaultProps: {
                    disableRipple: true, // No more ripple, on the whole application 💣!
                },
            },

            MuiButton: {
                styleOverrides: {
                    root: {
                        borderRadius: 8,
                        padding: '10px 16px',
                        fontWeight: 'bold',
                        transition: 'background-color 0.1s ease', // Adjust transition speed
                        '&:hover': {
                        },
                    },
                    contained: {
                        '&:hover': {
                            color: 'white',
                        },
                    },
                },
            },
        }
    });

    useEffect(() => {
        document.title = 'Safety Touch CMS';
    }, []);

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline/>
            <BrowserRouter>
                {/*<Header/>*/}
                <Routes>
                    <Route path="/" element={<Login/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/register" element={<Register/>}/>
                    <Route path="/logout" element={<Logout/>}/>

                <Route path="/dashboard" element={<AuthGuard route={'dashboard'}/>}>
                    <Route path="/dashboard" element={<Home/>}/>
                </Route>

                <Route path="/user" element={<AuthGuard route={'user'}/>}>
                      <Route path="/user" element={<UserMain />} />
                </Route>

                <Route path="/roles" element={<AuthGuard route={'role'} />}>
                      <Route path="/roles" element={<RoleMain />} />
                </Route>

                <Route path="/teams" element={<AuthGuard route={'teams'} />}>
                      <Route path="/teams" element={<TeamMain />} />
                </Route>

                <Route path="/quiz" element={<AuthGuard />}>
                      <Route path="/quiz" element={<QuizMain />}/>
                </Route>

                <Route path="/quizsection" element={<AuthGuard />}>
                      <Route path="/quizsection" element={<QuizsectionMain />} />
                </Route>

                <Route path="/quizuser" element={<AuthGuard />}>
                      <Route path="/quizuser" element={<QuizuserMain />} />
                </Route>

                <Route path="/questiontype" element={<AuthGuard />}>
                      <Route path="/questiontype" element={<QuestiontypeMain />} />
                </Route>

                    <Route path="/quizbuilder" element={<AuthGuard route={'quizbuilder'} />}>
                        <Route path="/quizbuilder" element={<QuizbuilderMain />} />
                    </Route>

                <Route path="/question" element={<AuthGuard />} >
                      <Route path="/question" element={<QuestionMain />} />
                </Route>

                <Route path="/questionoption" element={<AuthGuard />} >
                      <Route path="/questionoption" element={<QuestionoptionMain />} />
                </Route>

                <Route path="/quizanswer" element={<AuthGuard />} >
                      <Route path="/quizanswer" element={<QuizanswerMain />} />
                </Route>

                <Route path="/recordtype" element={<AuthGuard />} >
                      <Route path="/recordtype" element={<RecordtypeMain />} />
                </Route>

                <Route path="/record" element={<AuthGuard route={'record'} />}>
                      <Route path="/record" element={<RecordMain />} />
                </Route>

                <Route path="/recordcategory" element={<AuthGuard />} >
                      <Route path="/recordcategory" element={<RecordcategoryMain />} />
                </Route>

                <Route path="/touchconfiguration" element={<AuthGuard route={'touchconfiguration'} />}>
                      <Route path="/touchconfiguration" element={<TouchConfiguration />} />
                </Route>

                <Route path="/country" element={<AuthGuard route={'country'} />}>
                      <Route path="/country" element={<CountryMain />} />
                </Route>

                <Route path="/region" element={<AuthGuard route={'region'} />}>
                      <Route path="/region" element={<RegionMain />} />
                </Route>

                <Route path="/location" element={<AuthGuard route={'location'} />}>
                      <Route path="/location" element={<LocationMain />} />
                </Route>

                <Route path="/quizscore" element={<AuthGuard route={'quizscore'} />}>
                      <Route path="/quizscore" element={<QuizscoreMain />} />
                </Route>

                <Route path="/blog" element={<AuthGuard route={'blog'} />}>
                      <Route path="/blog" element={<BlogMain isNotes={false}/>} />
                </Route>

                <Route path="/notes" element={<AuthGuard route={'notes'} />}>
                      <Route path="/notes" element={<BlogMain isNotes={true}/>} />
                </Route>

                <Route path="/blogquiz" element={<AuthGuard />} >
                      <Route path="/blogquiz" element={<BlogquizMain />} />
                </Route>

                    <Route path="/privacy" element={<Privacypolicy />} />
                    <Route path="/terms" element={<Terms />} />

                {/* %TEMPLATE_INSERT_ROUTE%  */}

















                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default Router;
